import ThemeMaker from "../../components/thememaker";
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import { checkLogin } from "../../store/features/login/loginSlice";
import { useSelector, useDispatch } from "react-redux";
 import { useState,useEffect } from "react";
import { tokenDecode } from "../../utils/helper";
import { config } from "../../utils/config";
import axios from "axios";
const { apiEndpoint } = config;
var $ = require('jquery');

const Dashboard = () => {
    const[Dashboard, setDashboard] = useState([]);
    const [isLoading, setLoading] = useState(true)
    const decode = tokenDecode();
    const { data } = decode;
    const id = data.user.seller_id;
    const role_id = data.user.role_id;
    const user_id = data.user.id;
    const dat = useSelector((state: any) => state.loginReducer);
    const { permission: {category, order,product, role, seller, tax, user } } = data;
    const dashboardListing = async () => {
        try {
            const { data } = await axios({
                url: `${apiEndpoint}/total_countdata`,
                method: "POST",
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('us_st_d')}`
                },
                data: {
                    seller_id: decode?.data?.user?.seller_id
                }
            });
           
            if (data.status === 200) {
                console.log(data.data);
                setLoading(false);
                setDashboard(Object.values(data.data));
            }
        } catch (error) {
        }
    }
    
    useEffect(() => {
        dashboardListing();
    }, [])
    const style=()=>{
        
    }
    return (

        <ThemeMaker>
            <div className="container-fluid">
            
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 className="h3 mb-0 text-gray-800">Dashboard</h1>
                </div>

                <div className="row">
                {isLoading ? <div className="loader-wrapper"><div className="loader"></div></div>:null}

                {Dashboard.map((item,index) => (
                    
                <div className="col-xl-3 col-md-6 mb-4" key={index}>
                    <div className="card border-left-primary shadow h-100 py-2">
                        <div className="card-body">
                            <div className="row no-gutters align-items-center">
                               
                                <div className="col mr-2">
                                {index===0?<div className="text-xs font-weight-bold text-primary text-uppercase mb-1">{id===1?"Total Product":"Total User"}</div>:null}
                                {index===1?<div className="text-xs font-weight-bold text-success text-uppercase mb-1">{id===1?"Total Seller":"Total Order"}</div>:null}
                                {index===2?<div className="text-xs font-weight-bold text-info text-uppercase mb-1">{id===1?"Total Order":"Total Products"}</div>:null}
                                {index===3?<div className="text-xs font-weight-bold text-warning text-uppercase mb-1"> {id===1?"Pending Requests":"Weekly Order"}</div>:null}
                                <div className="h5 mb-0 font-weight-bold text-gray-800" >{item}</div>
                                </div>
                                <div className="col-auto">
                                {index===0?<i className="fas fa-calendar fa-2x text-gray-300"></i>:null}
                                {index===1?<i className="fas fa-dollar-sign fa-2x text-gray-300"></i>:null}
                                {index===2?<i className="fas fa-clipboard-list fa-2x text-gray-300"></i>:null}
                                {index===3?<i className="fas fa-comments fa-2x text-gray-300"></i>:null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                ))
                } 
                </div>
            </div>
        </ThemeMaker>


    )
}

export default Dashboard; 