import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Dashboard from "../pages/dashboard";
import PageNotFound from "../pages/404";
import Login from "../pages/login";
import AddUser from "../pages/adduser";
import UserList from "../pages/userlist";
import Profile from "../pages/profile";
import ProductList from "../pages/productlist";
import AddProduct from "../pages/addproduct";
import LoginPageIsLogedIn from "../components/loginProtected";
import ProtectedRoute from "../components/protectedRoute";
import ForgetPassword from "../pages/forget-password";
import ResetPassword from "../pages/reset-password";
import AddSeller from "../pages/addseller";
import SellerList from "../pages/sellerlist";
import Roles from "../pages/roles";
import OrderList from "../pages/orderlist";
// import Category from "../pages/category";
import Addcategory from "../pages/category/Category";
import UpdateSeller from "../pages/updateseller";
import Tax from "../pages/tax";
import UpdateUser from "../pages/updateuser";
import Location from "../pages/location";
import Logs from "../pages/logs";
import Transaction from "../pages/transaction";
import OrderDetails from "../pages/order-details";
import Shopdetails from "../pages/Shopdetails/Shopdetails";
import Brand from "../pages/Brand/Brand";
import Dynamicmain from "../pages/DynamicFields/Dynamicmain";
import DragDropAttributes from "../pages/DynamicFields/DragDropAttributes";
import Addattribute from "../pages/DynamicFields/Addattribute";
import Masterproductlist from "../pages/Masterproductlist/Masterproductlist";
import SellerNonApprovedlist from "../pages/Non Approved Product List/SellerNonApprovedlist";
import NonapprovedProducts from "../pages/Non Approved Product List/index";
import NonapprovedProduct from "../pages/NonApprovedProduct/index";
import MasterProductAdd from "../pages/Masterproductlist/MasterProductAdd";
import Completedorders from "../pages/orderlist/Completedorders";
import CanceledorderList from "../pages/orderlist/CanceledorderList";
import Returnorder from "../pages/orderlist/Returnorder";
import OrderCompleteinfo from "../pages/order-details/OrderCompleteinfo";

const routes = [
  {
    path: "/dashboard",
    component: <Dashboard />
  },
  {
    path: "/",
    component: <Login />
  },
  {
    path: "/addseller",
    component: <AddSeller />
  },
  {
    path: "/sellerlist",
    component: <SellerList />
  },
  {
    path: "/tax",
    component: <Tax />
  },
  {
    path: "/updateseller/:id",
    component: <UpdateSeller />
  },
  {
    path: "/roles",
    component: <Roles />
  },
  {
    path: "/adduser",
    component: <AddUser />
  },
  {
    path: "/updateuser/:id",
    component: <UpdateUser />
  },
  {
    path: "/userlist",
    component: <UserList />
  },
  {
    path: "/category",
    component: <Addcategory />
  },
  {
    path: '/product/:id?',
    component: <AddProduct />
  },
  {
    path: "/productlist",
    component: <ProductList />
  },
  {
    path: "/orderlist",
    component: <OrderList />
  },
  {
    path: "/orderdetails/:id?",
    component: <OrderDetails />
  },
  {
    path: "/transaction",
    component: <Transaction />
  },
  {
    path: "/profile",
    component: <Profile />
  },
  {
    path: "/location",
    component: <Location />
  },
  {
    path: "/forget-password",
    component: <ForgetPassword />
  },
  {
    path: "/reset-password/:id",
    component: <ResetPassword />
  }, {
    path: "/logs",
    component: <Logs />
  },
  {
    path: "/shopdetails",
    component: <Shopdetails />,
  },
  {
    path: "/brand",
    component: <Brand />,
  },
  {
    path: "/dynamic",
    component: <Dynamicmain />,
  },
  {
    path: "/dynamicattribute",
    component: <DragDropAttributes />,
  },
  {
    path: "/addattribute",
    component: <Addattribute />,
  },
  {
    path: "/masterproductlist",
    component: <Masterproductlist />,
  },
  {
    path: "/sellernonapprovedproducts",
    component: <SellerNonApprovedlist />,
  },
  {
    path: "/nonapprovedproducts",
    component: <NonapprovedProducts />,
  },
  {
    path: "/nonapprovedproduct/:id?",
    component: <NonapprovedProduct />,
  },
  {
    path: "/mastercatalog/:id?",
    component: <MasterProductAdd />,
  },
  {
    path: "/completedorders",
    component: <Completedorders />,
  },
  {
    path: "/canceledorders",
    component: <CanceledorderList />,
  },
  {
    path: "/returnedorders",
    component: <Returnorder />,
  },
  {
    path: "/ordercompleteinfo/:id?",
    component: <OrderCompleteinfo />,
  },
  {
    path: "*",
    component: <PageNotFound />
  }
]

const AllRoutes = () => {
  return (
    <>
      <Router>
        <Switch>
          {
            routes.map((item, index) => (
              <Route exact path={item?.path} key={index} >
                {
                  item.path === '/' ? (
                    <LoginPageIsLogedIn>
                      {item.component}
                    </LoginPageIsLogedIn>
                  ) : (
                    <>
                      <ProtectedRoute>
                        {item.component}
                      </ProtectedRoute>
                    </>
                  )
                }
              </Route>
            ))
          }
        </Switch>
      </Router>
    </>
  )
}

export default AllRoutes;