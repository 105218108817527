import { useState, useEffect } from "react";
import { v4 as uuidv4 } from 'uuid';
import ThemeMaker from "../../components/thememaker";
import { useParams } from "react-router-dom";
import { registerSeller } from "../../store/features/seller/sellerSlice";
import { useSelector, useDispatch } from "react-redux";
import { config } from "../../utils/config";
import { AnyAaaaRecord, AnyARecord } from "dns";
import { toast } from "react-toastify";

const aws = require('aws-sdk');
interface ICustomer {
    id: any;

}

const AddSeller = () => {
    const dispatch = useDispatch<any>();
    const _sellerData = useSelector((state: any) => state.sellerReducer);
    console.log("_sellerData", _sellerData);

    let params: ICustomer = useParams();


    const rawData = {
        name: '',
        email: '',
        company_name: '',
        contact_no: '',
        gst_no: '',
        pan_no: '',
        pan_name: '',
        pan_image: '',
        address_proof: '',
        address_proof_image: '',
        commission: 1,
        seller_id: 0,
        role_id: 2,
        status: 1
    }
    const rawErrorData = {
        name: false,
        email: false,
        company_name: false,
        contact_no: false,
        gst_no: false,
        pan_no: false,
        pan_name: false,
        pan_image: false,
        address_proof: false,
        address_proof_image: false,
        commission: false,
    }
    const [sellerData, setSellerData]: any = useState(rawData);
    const [sellerDataError, setSellerDataError]: any = useState(rawErrorData);


    const inputHandler = (e: any) => {
        setSellerData({ ...sellerData, [e.target.name]: e.target.value });
    }
    const errorHandler = (e: any) => {
        if (e.target.value) {
            setSellerDataError({ ...sellerDataError, [e.target.name]: "valid" });
        } else {
            setSellerDataError({ ...sellerDataError, [e.target.name]: true });
        }
    }
    const newUserCreatehandler = async () => {
        console.log("sellerData => ", sellerData);
        console.log("sellerDataError => ", sellerDataError);
        const { name,
            email,
            company_name,
            contact_no,
            gst_no,
            pan_no,
            pan_name,
            pan_image,
            address_proof,
            address_proof_image,
            commission,
            seller_id,
            role_id,
            status } = sellerData;
        if (name && email && company_name && contact_no && gst_no && pan_no && pan_image && pan_name && address_proof && address_proof_image && commission && seller_id === 0 && role_id && status) {
            dispatch(registerSeller(sellerData));
        } else {
            toast.error("Please fill all the fields !", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }
    const updateUserHandler = async () => {

    }
    const ImageHandler = async (e: any) => {
        try {
            const file = e.target.files[0];
            let myFile = file.name.split(".");
            let myFileType = myFile[myFile.length - 1];
            const s3 = new aws.S3({
                accessKeyId: config?.awsKeyId,
                secretAccessKey: config?.awsSecretKey
            });
            const params = {
                Bucket: "ondc-marketplace",
                Key: `images/${uuidv4()}.${myFileType}`,
                Body: file,
                ContentType: file?.type
            }
            let { Location } = await s3.upload(params).promise();
            return Location;
        } catch (exception) {
            console.log(exception);
            return false;
        }
    };

    const setImage = async (e: any) => {
        const image: any = await ImageHandler(e);
        console.log("Image----", image);
        setSellerData({ ...sellerData, [e.target.name]: image });
    }
    useEffect(() => {

    }, []);
    return (
        <>
            <ThemeMaker>

                <div className="row">
                    <div className="col-md-12 pl-4 pr-4">
                        <div className="card shadow mb-4">
                            <div className="card-header py-3">
                                <h6 className="m-0 font-weight-bold text-primary">
                                    Add a new seller
                                </h6>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label >
                                                Name
                                                <span className="text-danger" > * </span>
                                                {
                                                    sellerDataError?.name !== "valid" && sellerDataError?.name !== false ? (
                                                        <span className="text-danger tiny_size" > This field is required ! </span>
                                                    ) : null
                                                }
                                            </label>
                                            <input type="text" className="form-control" name="name" placeholder="Name"
                                                value={sellerData.name}
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label >Company name<span className="text-danger" >*</span>
                                                {
                                                    sellerDataError?.company_name !== "valid" && sellerDataError?.company_name !== false ? (
                                                        <span className="text-danger tiny_size" > This field is required ! </span>
                                                    ) : null
                                                }
                                            </label>
                                            <input type="text" className="form-control" name="company_name" placeholder="Company name"
                                                value={sellerData.company_name}
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label >Email address<span className="text-danger" >*</span>
                                                {
                                                    sellerDataError?.email !== "valid" && sellerDataError?.email !== false ? (
                                                        <span className="text-danger tiny_size" > This field is required ! </span>
                                                    ) : null
                                                }
                                            </label>
                                            <input type="text" name="email" className="form-control" placeholder="Email"
                                                value={sellerData.email}
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label >Contact No.<span className="text-danger" >*</span>
                                                {
                                                    sellerDataError?.contact_no !== "valid" && sellerDataError?.contact_no !== false ? (
                                                        <span className="text-danger tiny_size" > This field is required ! </span>
                                                    ) : null
                                                }
                                            </label>
                                            <input type="number" name="contact_no" className="form-control" placeholder="Contact number"
                                                value={sellerData.contact_no}
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}
                                                onKeyDown={(e: any) => {
                                                    if (e.target.value.length >= 12 && e.keyCode !== 8 && e.keyCode !== 46) {
                                                      e.preventDefault();
                                                    }
                                                  }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label >GST<span className="text-danger" >*</span>
                                                {
                                                    sellerDataError?.gst_no !== "valid" && sellerDataError?.gst_no !== false ? (
                                                        <span className="text-danger tiny_size" > This field is required ! </span>
                                                    ) : null
                                                }
                                            </label>
                                            <input type="text" className="form-control" name="gst_no" placeholder="GST number"
                                                maxLength={15}
                                                value={sellerData.gst_no}
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}

                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label >PAN Number <span className="text-danger" >*</span>
                                                {
                                                    sellerDataError?.pan_no !== "valid" && sellerDataError?.pan_no !== false ? (
                                                        <span className="text-danger tiny_size" > This field is required ! </span>
                                                    ) : null
                                                }
                                            </label>
                                            <input type="text" className="form-control" name="pan_no" placeholder="PAN number"
                                                maxLength={10}
                                                value={sellerData.pan_no}
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label >Name as per PAN <span className="text-danger" >*</span>
                                                {
                                                    sellerDataError?.pan_name !== "valid" && sellerDataError?.pan_name !== false ? (
                                                        <span className="text-danger tiny_size" > This field is required ! </span>
                                                    ) : null
                                                }
                                            </label>
                                            <input type="text" className="form-control" name="pan_name" placeholder="Name as per pan"
                                                value={sellerData.pan_name}
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label >PAN image <span className="text-danger" >*</span>
                                                {
                                                    sellerDataError?.pan_image !== "valid" && sellerDataError?.pan_image !== false ? (
                                                        <span className="text-danger tiny_size" > This field is required ! </span>
                                                    ) : null
                                                }
                                            </label>
                                            <input type="file" name="pan_image" className="form-control"
                                                onChange={(e) => setImage(e)}
                                                onBlur={(e) => errorHandler(e)}

                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <p> Address Proof <span className="text-danger" >*</span>
                                            {
                                                sellerDataError?.address_proof !== "valid" && sellerDataError?.address_proof !== false ? (
                                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                                ) : null
                                            }
                                        </p>
                                        <div className="form-group">
                                            <select name="address_proof" id="" className="form-control"
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}
                                            >
                                                <option value="">Select address proof</option>
                                                <option value="Aadhar card">Aadhar card</option>
                                                <option value="Ration card">Ration card</option>
                                                <option value="Telephone bill">Telephone bill</option>
                                                <option value="Bank Passbook">Bank Passbook</option>
                                                <option value="Electricity bill">Electricity bill</option>
                                                <option value="Lease or license agreement">Lease or license agreement</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <p> Address Proof image<span className="text-danger" >*</span>
                                            {
                                                sellerDataError?.address_proof_image !== "valid" && sellerDataError?.address_proof_image !== false ? (
                                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                                ) : null
                                            }
                                        </p>
                                        <div className="form-group">
                                            <input type="file" name="address_proof_image" className="form-control"
                                                onChange={(e) => setImage(e)}
                                                onBlur={(e) => errorHandler(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label >Commission ( % )<span className="text-danger" > *</span>
                                                {
                                                    sellerDataError?.commission !== "valid" && sellerDataError?.commission !== false ? (
                                                        <span className="text-danger tiny_size" > This field is required ! </span>
                                                    ) : null
                                                }
                                            </label>
                                            <input type="number" name="commission" className="form-control" placeholder="Commission"
                                                value={sellerData.commission}
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-12">

                                        <button type="submit" className="btn btn-primary"

                                            onClick={newUserCreatehandler}>
                                            {
                                                _sellerData?.loading ? 'saving...' : "Save"
                                            }
                                        </button>
                                    </div>
                                    {/* <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small> */}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </ThemeMaker>
        </>
    )
}

export default AddSeller;