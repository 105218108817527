import { useState, useEffect } from "react";
import ThemeMaker from "../../components/thememaker";
import axios from "axios";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { tokenDecode } from "../../utils/helper";
import { config } from "../../utils/config";
import { v4 as uuidv4 } from 'uuid';
import { Multiselect } from "multiselect-react-dropdown"
import thanks from "../../icons/thanks.gif"
import styles from "./Sellerdetails.module.css"
import styled from "styled-components"
import style from "../SellerRegisteration/seller_register.module.css"
import { show, hide } from "react-functional-modal"
import jwt_decode from "jwt-decode";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
const { apiEndpoint } = config;

const s3Config = {
    bucketName: 'bucket-name',
    dirName: 'directory-name',      /* Optional */
    region: 'ap-south-1',
    accessKeyId: 'ABCD12EFGH3IJ4KLMNO5',
    secretAccessKey: 'a12bCde3f4+5GhIjKLm6nOpqr7stuVwxy8ZA9bC0',
    s3Url: 'https:/your-aws-s3-bucket-url/'     /* Optional */
}
const aws = require('aws-sdk');




export default function Shopdetails() {

    const rawData = {
        opening_time: '',
        closing_days: "",
        opening_days: "",
        closing_time: '',
        images: "",
        customer_care_contact: "",
        delivery_fee: 0,
        packing_fee: 0,
        status: true,
        holidays: "10-12-23"

    };
    const errorRawData = {
        opening_time: false,
        closing_days: false,
        opening_days: false,
        closing_time: false,
        incorporation_date: false,
        images: false,
        commission: false,
        ondc_commission: false,
        fssai_licence: false,
        seller_commission: false,
        returnable: false,
        replaceable: false,
        customer_care_contact: false,
        cancelable: false,
        return_window: false,
        seller_pickup_return: false,
        time_to_ship: false,
        cod: false,
        // status: false,
        status: false

    };
    const [userData, setUserData]: any = useState(rawData);
    const [userDataError, setUserDataError] = useState(errorRawData);
    const [pageNamem, setpageName] = useState(true);
    const [loading, setLoading] = useState(false);
    const decode = tokenDecode();
    const seller_id = decode?.data?.user?.seller_id
    console.log("decode--", decode)

    useEffect(() => {
        getDetails()
    }, [])

    async function getDetails() {
        const { data } = await axios({
            url: `${apiEndpoint}/getShopDetails`,
            method: "POST",
            headers: {
                Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
            },
            data: { seller_id }

        })

        if (data.status === 200 && data.response.length > 0) {

            setUserData(data?.response[0]);
            setpageName(false);
            if (data?.response[0].holidays !== null) {
                let t = data?.response[0].holidays;
                let z = t.split(",")
                setSelectedDates(z)
            }

            // Split opening_days and closing_days by comma and trim any whitespace
            const openingDays = data.response[0].opening_days.split(",").map((day: any) => day.trim());
            if (data?.response[0].closing_days !== null) {
                const closingDays = data.response[0].closing_days.split(",").map((day: any) => day.trim());
                const filteredCDays: any = Cweekdays.filter(
                    (day) => closingDays.includes(day.id)
                );
                setSelectedCDays(filteredCDays.map((item: any) => item));
            }



            const filteredODays = Oweekdays.filter(
                (day) => openingDays.includes(day.id)
            );

            setSelectedODays(filteredODays.map((item: any) => item));

        }

    }
    const productImageHandler = async (e: any) => {
        try {
            const file = e.target.files[0];
            let myFile = file.name.split(".");
            let myFileType = myFile[myFile.length - 1];
            const s3 = new aws.S3({
                accessKeyId: "AKIAYJUL5VJOBYQMJDT7",
                secretAccessKey: "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"
            })
            const params = {
                Bucket: "ondc-marketplace",
                Key: `images/${uuidv4()}.${myFileType}`,
                Body: file,
                ContentType: file?.type
            }
            let result = await s3.upload(params).promise();
            const { Location } = result;


            setUserData({ ...userData, [e.target.name]: Location })

        } catch (exception) {
            // console.log(exception);
        }
    };


    const inputHandler = (e: any) => {
        setUserData({ ...userData, [e.target.name]: e.target.value });
    };

    const errorHandler = (e: any) => {
        if (e.target.value) {
            setUserDataError({ ...userDataError, [e.target.name]: false });
        } else {
            setUserDataError({ ...userDataError, [e.target.name]: true });
        }
    }

    const getRole = async () => {
        try {
            const { data } = await axios({
                url: `${apiEndpoint}/sellerShop`,
                method: "POST",
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
                },

            });

            if (data.status === 200) {
                setUserData(data.response);
                setpageName(false);
                // Split opening_days and closing_days by comma and trim any whitespace
                const openingDays = data.response.opening_days.split(",").map((day: any) => day.trim());
                const closingDays = data.response.closing_days.split(",").map((day: any) => day.trim());

                const filteredCDays = Cweekdays.filter(
                    (day) => closingDays.includes(day.id)
                );
                const filteredODays = Oweekdays.filter(
                    (day) => openingDays.includes(day.id)
                );

                setSelectedODays(filteredODays.map(item => item));
                // setSelectedCDays(filteredCDays.map((item: any) => item));
            }
        } catch (error) { }
    };


    console.log("usedata-----", userData)
    const submitSellerDetailsHandler = async () => {
        setLoading(true);

        try {
            const {
                opening_days,
                opening_time,
                closing_days,
                closing_time,
                // customer_care_contact,
                status,
                holidays,
                delivery_fee,
                packing_fee
            } = userData;

            if (opening_days && opening_time && closing_time) {
                const { data } = await axios({
                    url: `${apiEndpoint}/addSellerShop`,
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
                    },
                    data: { ...userData, holidays: selectedDates.toString() },
                });

                if (data.status == 200) {
                    toast.success("Details submitted Successfully", {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setUserData(data.response);


                }
            } else {
                toast.error("Please fill all the fields !", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            toast.error("404 error !", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };


    //code for the days selection:-

    const [Oweekdays, setOweekdays] = useState([
        { day: "Monday", id: "1" }, { day: "Tuesday", id: "2" }, { day: "Wednesday", id: "3" }, { day: "Thursday", id: "4" }, { day: "Friday", id: "5" }, { day: "Saturday", id: "6" }, { day: "Sunday", id: "7" },
    ])
    const [Cweekdays, setCweekdays] = useState([
        { cday: "Monday", id: "1" }, { cday: "Tuesday", id: "2" }, { cday: "Wednesday", id: "3" }, { cday: "Thursday", id: "4" }, { cday: "Friday", id: "5" }, { cday: "Saturday", id: "6" }, { cday: "Sunday", id: "7" },
    ])
    const [selectedCDays, setSelectedCDays]: any = useState();
    const [selectedODays, setSelectedODays]: any = useState();



    //code for the days selection end:-


    const onSelectHandler = (selectedList: any, selectedItem: any) => {
        let daySelected = selectedList.map((item: any) => item.id);
        let z = daySelected.toString()
        setUserData({ ...userData, opening_days: z });
        setSelectedODays(selectedList.map((item: any) => item));
    }
    const onRemoveHandler = (selectedList: any, selectedItem: any) => {
        let daySelected = selectedList.map((item: any) => item.id);
        let z = daySelected.toString()
        setUserData({ ...userData, opening_days: z });
        setSelectedODays(selectedList.map((item: any) => item));
    }

    const [startDate, setStartDate] = useState(new Date());
    const [selectedDates, setSelectedDates]: any = useState([]);

    const handleDateSelect = async (date: any) => {
        setStartDate(date)
        let z = date;
        let c = z.toLocaleDateString()
        const dateStr = c;
        // Create a new Date object by parsing the date string
        const dates = new Date(dateStr);

        // Extract the year, month, and day from the Date object
        const year = dates.getFullYear();
        const month = String(dates.getMonth() + 1).padStart(2, '0');
        const day = String(dates.getDate()).padStart(2, '0');

        // Format the date in the desired format
        const formattedDate = `${year}-${month}-${day}`;
        setSelectedDates((startDate: any) => [...startDate, formattedDate]);
    };

    const RemoveItem = (indexToRemove: any) => {
        let x = [...selectedDates]
        x.splice(indexToRemove, 1);
        setSelectedDates(x)
    }

    console.log("selected days--->", selectedDates)
    return (
        <>
            <ThemeMaker>
                <div className="row w-100 m-0 mt-2">
                    <div className="col-md-12 ">
                        <div className="card shadow mb-4">
                            <h6 className="ml-3 pt-2 font-weight-bold text-primary">
                                Shop Details
                            </h6>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Opening Time
                                                <span className="text-danger" > * </span>
                                                {
                                                    userDataError?.opening_time ? (
                                                        <span className="text-danger tiny_size" > This field is required ! </span>
                                                    ) : null
                                                }
                                            </label>
                                            <input
                                                type="time"
                                                className="form-control"
                                                name="opening_time"
                                                placeholder="Enter Opening Time"
                                                value={userData.opening_time}
                                                onChange={(e: any) => inputHandler(e)}
                                                onBlur={(e: any) => errorHandler(e)}
                                            />


                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Closing Time
                                                <span className="text-danger" > * </span>
                                                {
                                                    userDataError?.closing_time ? (
                                                        <span className="text-danger tiny_size" > This field is required ! </span>
                                                    ) : null
                                                }
                                            </label>
                                            <input
                                                type="time"
                                                name="closing_time"
                                                className="form-control"
                                                placeholder="Enter Closing Time"
                                                value={userData.closing_time}
                                                onChange={(e: any) => inputHandler(e)}
                                                onBlur={(e: any) => errorHandler(e)}
                                            />
                                        </div>
                                    </div>





                                    {/* <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Customer Care Contact
                                                <span className="text-danger" > * </span>
                                                {
                                                    userDataError?.customer_care_contact ? (
                                                        <span className="text-danger tiny_size" > This field is required ! </span>
                                                    ) : null
                                                }
                                            </label>
                                            <input
                                                type="text"
                                                name="customer_care_contact"
                                                className="form-control"
                                                value={userData.customer_care_contact}
                                                onChange={(e: any) => inputHandler(e)}
                                                onBlur={(e: any) => errorHandler(e)}
                                                placeholder="Customer Care Number"
                                            />
                                        </div>
                                    </div> */}



                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Delivery Charges

                                            </label>
                                            <input
                                                type="text"
                                                name="delivery_fee"
                                                className="form-control"
                                                value={userData.delivery_fee}
                                                onChange={(e: any) => inputHandler(e)}
                                                onBlur={(e: any) => errorHandler(e)}
                                                placeholder="Enter Delivery Charges"
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Packing Charges

                                            </label>
                                            <input
                                                type="text"
                                                name="packing_fee"
                                                className="form-control"
                                                value={userData.packing_fee}
                                                onChange={(e: any) => inputHandler(e)}
                                                onBlur={(e: any) => errorHandler(e)}
                                                placeholder="Enter Packing Charges"
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>
                                                <label className="mb-0">Opening Days
                                                    <span className="text-danger" > * </span>
                                                    {
                                                        userDataError?.opening_days !== false ? (
                                                            <span className="text-danger tiny_size" > This field is required ! </span>
                                                        ) : null
                                                    }
                                                </label>
                                            </label>
                                            {/* <input
                                                type="text"
                                                name="opening_days"
                                                className="form-control"
                                                placeholder="Enter Open Days"
                                                value={userData.opening_days}
                                                onChange={(e:any) => inputHandler(e)}
                                                 onBlur={(e: any) => errorHandler(e)}
                                            /> */}

                                            <Multiselect options={Oweekdays} selectedValues={selectedODays} displayValue="day" onSelect={onSelectHandler} onRemove={onRemoveHandler}></Multiselect>


                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Closing Days
                                                {/* <span className="text-danger" > * </span> */}
                                                {
                                                    userDataError?.closing_days !== false ? (
                                                        <span className="text-danger tiny_size" > This field is required ! </span>
                                                    ) : null
                                                }
                                            </label>

                                            <Multiselect options={Cweekdays} selectedValues={selectedCDays} displayValue="cday" onSelect={(selectedList, selectedItem) => {
                                                let daySelected = selectedList.map((item: any) => item.id)
                                                let z = daySelected.toString()
                                                setUserData({ ...userData, closing_days: z });
                                                setSelectedCDays(selectedList.map((item: any) => item));
                                            }}
                                                onRemove={(selectedList, selectedItem) => {
                                                    let daySelected = selectedList.map((item: any) => item.id);
                                                    let z = daySelected.toString()
                                                    setUserData({ ...userData, closing_days: z });
                                                    setSelectedCDays(selectedList.map((item: any) => item));
                                                }}></Multiselect>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">

                                            <label>Holidays   </label>
                                            {/* <span className="text-danger" > * </span> */}
                                            <div style={{ marginLeft: "5px", display: "inline-block" }}>
                                                <DatePicker selected={startDate} onChange={(date) => handleDateSelect(date)} />
                                            </div>


                                            <ul className="multiselect-container multiSelectContainer" style={{ display: "flex", marginTop: "3px", border: "1px solid grey", padding: "10px", borderRadius: "5px", flexWrap: "wrap", minWidth: "80%", minHeight: "40px" }}>
                                                {selectedDates.map((date: any, index: any) => (
                                                    <>
                                                        <li key={date.toString()}
                                                            style={{ padding: "10px", background: "#0096fb", color: "white", borderRadius: "40px", marginBottom: "22px", lineHeight: "10px", fontSize: "13px", display: "flex", justifyContent: "center" }}>
                                                            {date}

                                                            <span onClick={() => RemoveItem(index)}
                                                                style={{ margin: "-2px 0px 0px 10px" }} > < CancelOutlinedIcon style={{ fontSize: "14px", fontWeight: "600" }} /> </span>
                                                        </li>&nbsp;&nbsp;

                                                    </>
                                                ))}
                                            </ul>




                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        {pageNamem ? <button
                                            type="submit"
                                            className="btn btn-primary"
                                            onClick={submitSellerDetailsHandler}
                                        >
                                            {loading ? "saving..." : "Save"}
                                        </button> : <button
                                            type="submit"
                                            className="btn btn-success"
                                            onClick={submitSellerDetailsHandler}
                                        >
                                            {loading ? "saving..." : "Update"}
                                        </button>}
                                    </div>
                                    {/* <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ThemeMaker>

        </>
    )
}
