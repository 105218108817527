import { useState, useEffect, useRef } from "react";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import ThemeMaker from "../../components/thememaker";
import axios from "axios";
import { tokenDecode } from "../../utils/helper";
import { config } from "../../utils/config";
import { BasicTable } from "../../components/Table";
import { Paginated } from "../../components/Paginated";
import "../../components/table.css";
import Applyfilter from "../../components/Applyfilter";
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import Papa from 'papaparse';
import Alert from "../../components/Alert/Alert";

const { apiEndpoint } = config;
var $ = require("jquery");
var count = 1;


const ProductList = () => {

  const decoded = tokenDecode();
  let current_sellerid = decoded.data.user.seller_id
  const [productList, setProductList] = useState([]);
  const [sproductList, setSproductList] = useState([]);
  const [sellerList, setSellerList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sellers, setSellers]: any = useState({
    seller: "",
  });
  const rawData = {
    seller_id: "",
  };
  const rawpData = {
    seller_id: "",
    min_price: "",
  };
  const rawsData = {
    seller_id: "",
    sku: "",
  };
  const [categories, setCategories]: any = useState();
  const [searchData, setSearchData]: any = useState(rawData);
  const [searchpData, setSearchpData]: any = useState(rawpData);
  const [searchsData, setSearchsData]: any = useState(rawsData);

  const [valuename, setValuename] = useState("");
  const [valueprice, setValueprice] = useState("");
  const [valuesku, setValuesku] = useState("");
  const [toggle, setToggle] = useState(false);
  const [file, setFile]: any = useState(null);
  const decode = tokenDecode();
  const { data } = decode;
  const {
    permission: { category, order, product, role, seller, tax, user },
  } = data;





  const categoryListing = async () => {
    try {
      const { data } = await axios({
        url: `${apiEndpoint}/listProducts`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
        },
        data: {
          seller_id: decode?.data?.user?.seller_id,
        },
      });
      if (data.status === 200) {
        setProductList(data.data);
      }
    } catch (error) { }
  };
  const fetchSellerList = async () => {
    try {
      const { data } = await axios({
        url: `${apiEndpoint}/getsellerlist`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
        },
      });
      if (data.status === 200) {
        setSellerList(data.data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const fetchCatgoryList = async () => {
    try {
      const { data } = await axios({
        url: `${apiEndpoint}/getcategorylist`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
        },
      });
      if (data.status === 200) {
        setCategoryList(data.data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const deleteProduct = async (id: any) => {
    try {
      const { data } = await axios({
        url: `${apiEndpoint}/deleteProduct`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
        },
        data: { id },
      });
      if (data.status === 200) {
        setProductList(productList.filter((item: any) => item.id !== id));
        toast.success("Product deleted!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error("403 error !", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      toast.error("403 error !", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  const inputHandler = async (e: any) => {
    var optionvalue = e.target.value;
    setValuename("");
    setValueprice("");
    setValuesku("");
    if (optionvalue) {
      try {
        setSearchData({ ...searchData, [e.target.name]: e.target.value });
        setSearchpData({ ...searchpData, [e.target.name]: e.target.value });
        setSearchsData({ ...searchsData, [e.target.name]: e.target.value });
        setToggle(true);
        /*if(e.target.name==="seller"){etSearchpData({ ...searchpData, [e.target.name]: e.target.value });
                setSellers({[e.target.name]: e.target.value})
            }
            if(e.target.name==="category"){
                setCategories({[e.target.name]: e.target.value})
            }
            console.log(sellers);*/
        console.log(optionvalue);
        const { data } = await axios({
          url: `${apiEndpoint}/filterproductbyseller`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
          },
          data: {
            seller_id: optionvalue,
          },
        });

        if (data.status === 200) {
          setProductList(data.response);
          setSproductList(data.response);
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      setProductList(sproductList);
    }
  };
  const inputeHandler = async (e: any) => {
    const optionvalue = e.target.value;
    setValuename(optionvalue);
    if (optionvalue) {
      try {
        const { seller_id } = searchData;

        setToggle(true);
        setSearchData({ ...searchData, [e.target.name]: e.target.value });

        const { data } = await axios({
          url: `${apiEndpoint}/filterbysellerproduct`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
          },
          data: {
            seller_id: { searchData },
            name: optionvalue,
          },
        });

        if (data.status === 200) {
          setProductList(data.response);
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      inputHandler(e);
    }
  };
  const inputeeHandler = async (e: any) => {
    const optionvalue = e.target.value;
    setValuename(optionvalue);
    if (optionvalue) {
      try {
        setToggle(true);
        setSearchData({ ...searchData, [e.target.name]: e.target.value });

        const { data } = await axios({
          url: `${apiEndpoint}/filterproductsellerlogin`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
          },
          data: {
            name: optionvalue,
          },
        });

        if (data.status === 200) {
          setProductList(data.response);
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      categoryListing();
    }
  };
  const inputpHandler = async (e: any) => {
    const optionvalue = e.target.value;
    setValueprice(optionvalue);
    if (optionvalue) {
      try {
        const { seller_id, min_price } = searchpData;
        setToggle(true);

        setSearchpData({ ...searchpData, [e.target.name]: e.target.value });
        console.log("line 239" + rawpData);
        const { data } = await axios({
          url: `${apiEndpoint}/filterbysellerproduct`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
          },
          data: {
            seller_id: { searchData },
            tax_included_price: optionvalue,
          },
        });

        if (data.status === 200) {
          alert("hello")
          setProductList(data.response);
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      inputHandler(e);
    }
  };
  const inputppHandler = async (e: any) => {
    const optionvalue = e.target.value;
    setValueprice(optionvalue);
    if (optionvalue) {
      try {
        setToggle(true);

        setSearchpData({ ...searchpData, [e.target.name]: e.target.value });
        console.log("line 239" + rawpData);
        const { data } = await axios({
          url: `${apiEndpoint}/filterproductsellerlogin`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
          },
          data: {
            tax_included_price: optionvalue,
          },
        });

        if (data.status === 200) {
          setProductList(data.response);
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      categoryListing();
    }
  };
  const inputsHandler = async (e: any) => {
    const optionvalue = e.target.value;
    setValuesku(optionvalue);
    if (optionvalue) {
      try {
        const { seller_id, sku } = searchsData;
        console.log(searchsData);
        setToggle(true);

        setSearchsData({ ...searchsData, [e.target.name]: e.target.value });
        console.log("line 195" + rawsData);
        const { data } = await axios({
          url: `${apiEndpoint}/filterbysellerproduct`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
          },
          data: {
            seller_id: { searchData },
            sku: optionvalue,
          },
        });

        if (data.status === 200) {
          setProductList(data.response);
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      inputHandler(e);
    }
  };
  const inputssHandler = async (e: any) => {
    const optionvalue = e.target.value;
    setValuesku(optionvalue);
    if (optionvalue) {
      try {
        console.log(searchsData);
        setToggle(true);

        setSearchsData({ ...searchsData, [e.target.name]: e.target.value });
        console.log("line 195" + rawsData);
        const { data } = await axios({
          url: `${apiEndpoint}/filterproductsellerlogin`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
          },
          data: {
            sku: optionvalue,
          },
        });

        if (data.status === 200) {
          setProductList(data.response);
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      categoryListing();
    }
  };
  const inputHandlerforcategory = async (e: any) => {
    try {
      var optionvalue = e.target.value;
      const { data } = await axios({
        url: `${apiEndpoint}/filtercategory`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
        },
        data: {
          category_id: optionvalue,
        },
      });
      console.log("product data", data.response);

      if (data.status === 200) {
        setProductList(data.response);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleImport = async (event: any) => {
    // const file = event.target.files?.[0];
    console.log("file---->", file)
    Papa.parse(file, {
      header: true,
      dynamicTyping: true,
      complete: ({ data }) => {
        console.log(data)
        let abc = data.filter((item: any) => item.name !== null)
        let orders = abc.map((order: any) => ({
          name: order.name,
          position: order.position,
          product_type: order.product_type,
          pf_brand_owner_FSSAI_license_no: order.pf_brand_owner_FSSAI_license_no,
          short_description: order.short_description,
          description: order.description,
          pc_manufacturer_or_packer_name: order.pc_manufacturer_or_packer_name,
          // pc_common_of_generic_name_of_commodity: order.pc_common_of_generic_name_of_commodity,
          // pc_common_of_generic_name_of_commodity_in_pkg: order.pc_common_of_generic_name_of_commodity_in_pkg,
          // pc_month_year_of_manufacturer_packing_import: order.pc_month_year_of_manufacturer_packing_import,
          // pf_nutritional_info: order.pf_nutritional_info,
          // pf_additives_info: order.pf_additives_info,
          // pf_other_fassai_license_no: order.pf_other_fassai_license_no,
          // pf_importer_fassai_license_no: order.pf_importer_fassai_license_no,                    
          // pc_manufacturer_or_packer_address: order.pc_manufacturer_or_packer_address,
          mrp: order.mrp,
          uom: order.uom,
          selling_price: order.selling_price,
          // tax_included_price: order.tax_included_price,
          tax_included: order.tax_included,
          tax: order.tax,
          hsn: order.hsn,
          tags: order.tags,
          sku: order.sku,
          country_of_origin: order.country_of_origin,
          customer_care_contact: order.customer_care_contact,
          time_to_ship: order.time_to_ship,
          returnable: order.returnable,
          cancelable: order.cancelable,
          category_name: order.category_name,
          return_window: order.return_window,
          seller_pickup_return: order.seller_pickup_return,
          replaceable: order.replaceable,
          cod: order.cod,
          image: order.image,
          status: order.status,
          approve_status: order.approve_status,
          retail_approve_status: order.retail_approve_status,
          // brand: order.brand,
          // upc: order.upc,
          hair_type: order.hair_type,
          herbal_or_ayurvedic: order.herbal_or_ayurvedic,
          quantity: order.quantity,
          net_qty: order.net_qty,
          ready_to_eat: order.ready_to_eat,
          ready_to_cook: order.ready_to_cook,
          rice_grain_type: order.rice_grain_type,
          capacity: order.capacity,
          preservatives: order.preservatives,
          preservatives_details: order.preservatives_details,
          flavours_and_spices: order.flavours_and_spices,
          scented_or_flavour: order.scented_or_flavour,
          theme_or_occasion_type: order.theme_or_occasion_type,
          recommended_age: order.recommended_age,
          mineral_source: order.mineral_source,
          caffeine_content: order.caffeine_content,
          absorption_duration: order.absorption_duration,
          baby_weight: order.baby_weight,
          brand_name: order.brand_name,
          weight: order.weight
          //extraas:-
          // seller_id: order.seller_id,
          // category: order.category,
          // ondc_product_id: order.ondc_product_id,

        }))
        let jsonData = {
          orders: orders
        };
        // console.log(JSON.stringify(jsonData))
        sendDataToDb(jsonData)
      },
    });
  };

  async function sendDataToDb(jsonData: any) {
    try {
      setLoading(true);
      console.log(jsonData)
      let result = await axios({
        url: `${apiEndpoint}/uploadFileData`,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('us_st_d')}`,
          // "Content-Type": "multipart/form-data"

        },
        data: jsonData,

      })
      // console.log(result)
      setLoading(false);
      if (result.status === 200) {
        // console.log("success")
        toast.success("Products added successfully !", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setFile(null)
        categoryListing()
        // document.getElementById('inputFile').value = ''
        let inputFile = document.getElementById('inputFile') as HTMLInputElement
        inputFile.value = ""
      } else {
        // console.log("this is from succes if")
        toast.error("403 error !", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }

    } catch (error) {
      setLoading(false);
      // console.log("this is from catch")
      toast.error("403 error !", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    console.log("jsonData----->", jsonData)

  }

  const COLUMNS = [
    {
      Header: "Sn No.",
      Footer: "Sn No.",
      accessor: (row: any, count: any) => {
        return <>{count + 1}</>;
      },
      disableFilters: true,
      sticky: "left",
    },
    {
      Header: "Image",
      Footer: "Image",
      accessor: (row: any) => {
        return (
          <img
            src={
              row.image
                ? row.image
                : "http://cdn.onlinewebfonts.com/svg/img_546302.png"
            }
            alt="ok"
            height={80}
            width={80}
          />
        );
      },
      sticky: "left",
    },
    {
      Header: "Name",
      Footer: "Name",
      accessor: "name",
      sticky: "left",
    },
    {
      Header: "Price",
      Footer: "Price",
      accessor: "tax_included_price",
      sticky: "left",
    },
    {
      Header: "Category",
      Footer: "Category",
      accessor: "product_type_name",
      sticky: "left",
    },
    {
      Header: "Sku",
      Footer: "Sku",
      accessor: "sku",
      sticky: "left",
    },
    {
      Header: "Status",
      Footer: "Status",
      accessor: (row: any) => {
        return <>{row.status ? "Active" : "Draft"}</>;
      },
      sticky: "left",
    },

    {
      Header: "Action",
      Footer: "Action",
      accessor: (row: any, index: any) => {
        return (
          <div className="d-flex justify-content-space-evenly" style={{ width: "170px", margin: "0 auto" }}>
            {product.update ? (
              <Link
                to={`../../product/${row.id}`}
                className="btn btn-success btn-circle"
                style={{ height: "30px", width: "30px" }}
              >
                <i className="fas fa-pen"></i>
              </Link>
            ) : null}
            {product.delete ? (
              <button
                className="btn btn-danger btn-circle"
                style={{ height: "30px", width: "30px" }}
                onClick={() => deleteProduct(row.id)}
              >
                <i className="fas fa-trash"></i>
              </button>
            ) : null}

            {/* //----------------------------------------------HTML FOR QUICK EDIT START-------------------------------- */}


            {product.update ? <>
              <button className="btn btn-secondary btn-circle" type="button" style={{ height: "30px", width: "30px" }} >
                <span onClick={(e) => {
                  e.preventDefault()
                  setopenInput(row.id)
                  // console.log("from spab")	
                  maxPriceHandler(row.id)
                }}>  QU</span>
              </button>
              <>
                {openInput === row.id && (<div style={{ position: "relative" }}>
                  <div className="pop-up" style={{ width: "170px" }}>
                    <div className="d-flex justify-content-between align-items-center w-100 pr-1">

                      <p style={{ fontWeight: "600", fontSize: "12px", color: "black", paddingTop: "10px", textAlign: "center", width: "100%" }} className="p-1 m-0">Max Price</p>



                      <ClearOutlinedIcon style={{ color: "black", fontSize: "12px", display: "inline-block", marginBottom: "3px", cursor: "pointer" }}
                        onClick={() => setopenInput(null)}
                      />
                    </div>

                    <div className="pop-up-content">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control form-control-sm w-75"
                          style={{ margin: "5px auto" }}
                          value={quickUpdatedMaxPrice}
                          // className="w-50"	
                          onChange={(e) => {
                            setquickUpdatedMaxPrice(e.target.value)
                          }}
                        />
                        <button style={{ marginLeft: "2px", backgroundColor: "blue", padding: "2px 5px", borderRadius: "3px", border: "none" }} onClick={() => {
                          QuickUpdateHandler(row.id, row.mrp)
                        }}> Update </button>
                      </div>
                    </div>
                  </div>
                </div>)}

              </>
            </> : null}

            {/* //----------------------------------------------HTML FOR QUICK EDIT END-------------------------------- */}



            {/* //----------------------------------------------HTML FOR SWITCH START-------------------------------- */}
            {product.update ? <div style={{ display: "inline" }}>
              <label className="switch">
                <input
                  type="checkbox"
                  checked={row?.status}
                  onChange={async (e) => {
                    // console.log("item status-->", item.status)
                    let newIsChecked = !row?.status;
                    let itemId = row?.id;

                    const { data } = await axios({
                      url: `${apiEndpoint}/changeproductstatus`,
                      method: "POST",
                      headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                          "us_st_d"
                        )}`,
                        "Content-type": "application/json",
                      },
                      data: { product_id: itemId, isChecked: newIsChecked },
                    });
                    const updatedProductlist: any = [...productList];
                    updatedProductlist[index] = {
                      ...row,
                      status: newIsChecked,
                    };
                    setProductList(updatedProductlist);
                  }}
                />
                <span className="slider round"></span>
              </label>
            </div> : null}


            {/* //----------------------------------------------HTML FOR SWITCH END-------------------------------- */}
          </div>



        );
      },
      sticky: "left",
    },
  ];
  count++;
  useEffect(() => {
    categoryListing();
    fetchSellerList();
    fetchCatgoryList();
  }, []);

  // ------------------------------------------CODE FOR QUICK UPDATE START----------------------------------------------
  const [quickUpdatedMaxPrice, setquickUpdatedMaxPrice] = useState("")
  const [quickUpdatedMaxPrice2, setquickUpdatedMaxPrice2] = useState("")
  const [openInput, setopenInput] = useState(null)
  function maxPriceHandler(id: any) {
    // console.log("clicked");	
    setopenInput(openInput === id ? null : id);
    const maxPriceProduct: any = productList.filter(item => item === id);
    setquickUpdatedMaxPrice2(maxPriceProduct)
    console.log("maxPriceProduct----->", maxPriceProduct)
    let mp = maxPriceProduct[0]?.mrp
    // console.log(mp)	
    // setmaxprice(mp);	
    setquickUpdatedMaxPrice(mp);
  }

  const [showAlert, setshowAlert]: any = useState(false)
  async function QuickUpdateHandler(id: any, mrp: any) {
    console.log("quickUpdatedMaxPrice", quickUpdatedMaxPrice);
    if (parseInt(quickUpdatedMaxPrice) <= parseInt(mrp)) {
      let updatedDataToSend;
      let dataForSeller2 = {
        product_id: id,
        price: quickUpdatedMaxPrice,
        status: true,
      };
      // if (current_sellerid === 1) {
      //   updatedDataToSend = {
      //     ...quickUpdatedMaxPrice2,
      //     tax_included_price: quickUpdatedMaxPrice,
      //   };
      // } else {
      //   updatedDataToSend = dataForSeller2;
      // }
      updatedDataToSend = dataForSeller2;
      // console.log(updatedDataToSend)
      const { data } = await axios({
        url: `${apiEndpoint}/quickeditprice`,
        method: "post",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
        },
        data: updatedDataToSend,
      });

      if (data.status === 200) {
        setopenInput(null);
        toast.success("Price Updated successfully !", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setTimeout(() => {
          categoryListing();
        }, 1000);
      }

      // if (current_sellerid===1) {
      //   if (data.status===200) {
      //     categoryListing();
      //   }
      // }
    } else {
      // alert("Please Enter an amount less then MRP");
      // <Alert />
      setshowAlert(true)

    }
  }
  // ------------------------------------------CODE FOR QUICK UPDATE END----------------------------------------------

  function updateProductList(productList: any) {
    setProductList(productList)
  }

  function handleSort(e: any) {
    console.log(e.target.value);
    if (e.target.value == 0) {
      const sortedLowToHigh = [...productList].sort((a: any, b: any) => {
        return parseInt(a.tax_included_price) - parseInt(b.tax_included_price);
      });
      setProductList(sortedLowToHigh);
    } else if (e.target.value == 1) {
      const sortedHightoLow = [...productList].sort((a: any, b: any) => {
        return parseInt(b.tax_included_price) - parseInt(a.tax_included_price);
      });
      console.log("sortedHightoLow--->", sortedHightoLow)
      setProductList(sortedHightoLow);
    } else {
      categoryListing();
    }
  }
  return (
    <>
      <ThemeMaker>
        <div className="row">
          <div className="col-md-12 pl-4 pr-4">
            <div className="card shadow mb-4">
              <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-primary text-left">
                  Product list
                </h6>

                {showAlert ? <Alert showAlert={showAlert} setshowAlert={setshowAlert}/> : null}

                {/* {(current_sellerid == 1) && <>
                  <div className="importingSection">
                    <input type="file" id="inputFile" onChange={(e: any) => {
                      // console.log("change working");
                      setFile(e.target.files[0]);
                    }} style={{ marginTop: "30px" }} />
                    <button className="btn btn-primary" onClick={(e: any) => handleImport(e)} > Submit </button>

                  </div> </>} */}
                {seller?.read ? (
                  <>
                    <b>Filter By Seller: </b>
                    <select
                      name="seller_id"
                      id=""
                      onChange={(e: any) => inputHandler(e)}
                    >
                      <option value="">Select Seller</option>
                      {sellerList.map((item: any) => (
                        <option value={item?.seller_id}>{item?.name}</option>
                      ))}
                    </select>
                  </>
                ) : (
                  ""
                )}

                {/*<b>Filter By Category: </b><select name="category" id="" onChange={(e:any)=>inputHandlerforcategory(e)} defaultValue="">
                                <option value="">Select Category</option>
                                {
                                    categoryList.map((item:any)=>(
                                        <option value={item?.id}>{item?.category_name}</option>
                                    ))
                                }
                                       
                            </select>*/}
                <div className="search mt-5">
                  <div className="row">
                    <input
                      type="search"
                      name="name"
                      placeholder="Search by name"
                      className="mr-2"
                      onChange={(e) => {
                        seller?.read ? inputeHandler(e) : inputeeHandler(e);
                      }}
                      value={valuename ? valuename : ""}
                    />
                    <input
                      type="search"
                      name="min_price"
                      placeholder="Search by price"
                      className="mr-2"
                      onChange={(e) => {
                        seller?.read ? inputpHandler(e) : inputppHandler(e);
                      }}
                      value={valueprice ? valueprice : ""}
                    />
                    <input
                      type="search"
                      name="sku"
                      placeholder="Search by sku"
                      className="mr-2"
                      onChange={(e) => {
                        seller?.read ? inputsHandler(e) : inputssHandler(e);
                      }}
                      value={valuesku ? valuesku : ""}
                    />
                  </div>
                </div>

                {/* -------------------------------------------- search & filter start ---------------------------------------- */}
                <div className="col-md-12">
                  {/* <input	
                  type="search"	
                  name="name"	
                  placeholder="Search by Product, Category or Brand"	
                  className="w-100 mr-2 rounded-pill"	
                  value={searchValue}	
                  onChange={searchHandler}	
                  onKeyPress={searchResult}	
                  style={{ fontSize: "14px" }}	
                /> */}
                </div>
                <div className="col-md-12 btn-toolbar p-0 d-flex  align-items-center" role="toolbar" style={{ gap: "10px" }}>
                  <Applyfilter updateProductList={updateProductList} getproductlist={categoryListing} url="filterinstore"></Applyfilter>
                  <div className="btn-group" role="group" style={{ width: "17%" }}>
                    <select
                      // type="button"	
                      className="btn btn-secondary w-100  "
                      style={{ fontSize: "14px", margin: "5px 0px" }}
                      onChange={handleSort}
                    >
                      <option value="">Sort</option>
                      <option value="0">Low - High</option>
                      <option value="1">High - Low</option>
                    </select>
                  </div>
                </div>
                {/* -------------------------------------------- search & filter end ---------------------------------------- */}
              </div>



              <div className="card-body">
                <div className="table-responsive">
                  {/*} <table className="table table-bordered" id="dataTable" width="100%" >
                                        <thead>
                                            <tr>
                                                <th>Sn</th>
                                                <th>Image</th>
                                                <th>Name</th>
                                                <th>Price</th>
                                                <th>Sku</th>
                                                <th>Status</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                productList.map((item: any, index: any) => (
                                                    <tr key={item?.id}>
                                                        <td> {index + 1} </td>
                                                        <td>
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <img src={item?.image ? item?.image : "http://cdn.onlinewebfonts.com/svg/img_546302.png"} alt="ok" height={80} width={80} />
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td> {item?.name} </td>
                                                        <td> {item?.min_price} </td>
                                                        <td> {item?.sku}</td>
                                                        <td> {item?.status ? "Active" : "Draft"}</td>

                                                        <td>
                                                            <div className="row">
                                                                {
                                                                    product?.update ? (
                                                                        <div className="col-md-4">
                                                                            <Link to={`/product/${item?.id}`} className="btn btn-success btn-circle">
                                                                                <i className="fas fa-eye"></i>
                                                                            </Link>
                                                                        </div>
                                                                    ) : null
                                                                }
                                                                {
                                                                    product?.delete ? (
                                                                        <div className="col-md-4">
                                                                            <button className="btn btn-danger btn-circle"
                                                                                onClick={() => deleteProduct(item?.id)}
                                                                            >
                                                                                <i className="fas fa-trash"></i>
                                                                            </button>
                                                                        </div>
                                                                    ) : null
                                                                }

                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>*/}
                  <Paginated data={productList} columns={COLUMNS} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </ThemeMaker>
    </>
  );
};

export default ProductList;
