import { useState, useEffect, useRef } from "react";
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import axios from "axios";
import ThemeMaker from "../../components/thememaker";
import { tokenDecode } from "../../utils/helper";
import { toast } from "react-toastify";
import { config } from "../../utils/config";
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DeleteIcon from '@mui/icons-material/Delete';
import EditNoteTwoToneIcon from '@mui/icons-material/EditNoteTwoTone';
import TreeItem from '@mui/lab/TreeItem';
const { apiEndpoint } = config
var $ = require('jquery');


let arr = []
let Ndata = arr;
const Addcategory = () => {
    const decoded = tokenDecode();
    const { data } = decoded;
    const { permission: { category } } = data;
    const [listing, setListing] = useState(true);
    let rawSubCatData = {
        parent_id: "",
        cat_name: "",
        status: true,
        id: ""
    }
    const [Subcategorydata, setSubcategorydata] = useState(rawSubCatData);
    const [subcat, setSubcat] = useState(false)
    const [subcattoggle, setSubcattoggle] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [listingLoading, setListingLoading] = useState(false);
    const [taxData, setTaxData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [catlist, setCatlist] = useState('');
    const [Nadata, setNadata] = useState([]);
    const [show, setshow] = useState("categorylist")
    // const [isChecked, setIsChecked] = useState(false);

    const [tax, setTax] = useState({
        tax: '',
        status: true,
        id: ''
    });

    const taxD = {
        tax: false,
        name: false,
        parent_id: false
    }
    const [taxErrorData, setTaxErrorData] = useState(taxD);
    const errorHandler = (e) => {
        if (e.target.value) {
            setTaxErrorData({ ...taxErrorData, [e.target.name]: false });
        } else {
            setTaxErrorData({ ...taxErrorData, [e.target.name]: true });
        }
    }


    const createTaxHandler = async () => {
        try {
            setLoading(true);
            if (tax?.tax) {
                const { data } = await axios({
                    url: `${apiEndpoint}/addcategories`,
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('us_st_d')}`
                    },
                    data: {
                        cat_name: tax.tax,
                        status: tax.status
                    }
                });

                if (data.status === 200) {
                    taxListHanler()
                    toast.success("New Category created !", {
                        position: "top-right",
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setTax({
                        tax: '',
                        status: true,
                        id: ''
                    })
                } else {
                    toast.error("403 Error !", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            } else {
                toast.error("Please fill the required field !", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            toast.error("403 Error !", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }


    const createSubCatHandler = async () => {
        console.log("data to send-->", Subcategorydata)
        try {
            setLoading(true);

            if (Subcategorydata?.cat_name && Subcategorydata?.parent_id) {
                const { data } = await axios({
                    url: `${apiEndpoint}/addsubcategories`,
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('us_st_d')}`
                    },
                    data: Subcategorydata
                });

                if (data.status === 200) {
                    taxListHanler()
                    toast.success("New Sub Category created !", {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setSubcategorydata(rawSubCatData)


                } else {
                    toast.error("403 Error !", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            } else {
                toast.error("Please fill the required field !", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            toast.error("403 Error !", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    const taxListHanler = async () => {
        try {
            setListing(true);
            setSubcat(false)
            setListingLoading(true);

            const { data } = await axios({
                url: `${apiEndpoint}/getcategories`,
                method: "POST",
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('us_st_d')}`
                }
            });

            setListingLoading(false);
            if (data.status === 200) {
                setTaxData(data.response);
                console.log("data response--->", data.response)

            }

        } catch (error) {
            setListingLoading(false);


        }
    }

    const setCrete = () => {
        setListing(false);
        setIsUpdate(false);
        setSubcat(false);
        setTax({
            tax: '',
            status: true,
            id: ''
        })
    }
    const setsubCrete = async () => {
        setTax({
            tax: '',
            status: true,
            id: ''
        })
        setSubcategorydata(rawSubCatData)
        setListing(false);
        setIsUpdate(false);
        setSubcat(true);
        try {
            setListing(false);
            setListingLoading(false);
            const { data } = await axios({
                url: `${apiEndpoint}/getcategories`,
                method: "POST",
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('us_st_d')}`
                }
            });
            // console.log("tax listing", data);
            setListingLoading(false);
            if (data.status === 200) {
                Ndata = data.response;
                const parsedData = data.response.map(item => ({
                    id: item.id,
                    parent_id: item.parent_id,
                    cat_name: item.cat_name,
                    status: item.status,
                    subcategory: item.subcategory || []
                }));
                // console.log("parsed data-->", parsedData)
                setNadata(parsedData);


            }

        } catch (error) {
            setListingLoading(false);


        }
    }


    const editRedirect = async (id, redirectText, parentCatName) => {
        console.log("id---", id);

        setshow(redirectText)
        setSubcattoggle(true)
        setsubCrete()
        try {
            const { data } = await axios({
                url: `${apiEndpoint}/getcatsub`,
                method: "POST",
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('us_st_d')}`
                },
                data: {
                    "id": id
                }
            });
            // console.log("editRedirect data", data);
            if (data.status === 200) {
                setTax({
                    tax: data?.parent_id[0]?.cat_name,
                    status: data?.parent_id[0]?.status,
                    id: data?.parent_id[0]?.id
                });
                setSubcategorydata({
                    parent_id: data?.parent_id[0]?.parent_id,
                    cat_name: data?.parent_id[0]?.cat_name,
                    status: data?.parent_id[0]?.status,
                    id: data?.parent_id[0]?.id
                })
                // setSelectsub(data?.parent_id[0]?.parent_id)

            }
        } catch (error) {

        }
        setIsUpdate(true);
        setListing(false);
        // if (parentCatName !== "") {
        //     setSubcat(true)
        // } else {
        //     setSubcat(false);
        // }


    }


    const updateTax = async () => {
        try {
            if (tax?.tax) {
                setLoading(true);
                // console.log("tax", tax);
                const { data } = await axios({
                    url: `${apiEndpoint}/updatecategories`,
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('us_st_d')}`
                    },
                    data: {
                        "cat_name": tax.tax,
                        "status": tax.status,
                        id: tax.id
                    }
                });
                // console.log("data----", data);
                if (data.status === 200) {
                    taxListHanler()
                    toast.success("Category Updated !", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else {
                    toast.error("403 Error !", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            } else {
                toast.error("Please fill the required field !", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            toast.error("403 Error !", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    const updateSubTax = async () => {
        try {
            if (Subcategorydata?.cat_name) {
                setLoading(true);
                const { data } = await axios({
                    url: `${apiEndpoint}/updatesubcategories`,
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('us_st_d')}`
                    },
                    data: {
                        "cat_name": Subcategorydata.cat_name,
                        "parent_id": Subcategorydata.parent_id,
                        id: Subcategorydata.id,
                        status: Subcategorydata.status
                    }
                });

                if (data.status === 200) {
                    taxListHanler()
                    toast.success("Category Updated !", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else {
                    toast.error("403 Error !", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            } else {
                toast.error("Please fill the required field !", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            toast.error("403 Error !", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    console.log("Subcategorydata----->", Subcategorydata)
    console.log("tax----->", tax)

    const deleteTax = async (id, parent_id) => {
        try {
            setLoading(true);

            const { data } = await axios({
                url: `${apiEndpoint}/delcatshiftsubcat`,
                method: "POST",
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('us_st_d')}`
                },
                data: { id, parent_id }
            });
            if (data.status === 200) {
                taxListHanler()
                toast.success("Category Deleted !", {
                    position: "top-right",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });


                setTimeout(() => {
                    window.location.reload(false)
                }, 1000);

            } else {
                toast.error("403 Error !", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (error) {
            toast.error("403 Error !", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }


    const handleSubcategoryChange = (id) => {
        setSubcategorydata({ ...Subcategorydata, parent_id: id });
    };


    const MakeCategory = ({ cat_name, subcategory, id, onSubcategoryChange }) => {
        const subCategoryChecker = subcategory && subcategory.length >= 1;
        const handleCheckboxChange = (event) => {

            setSubcategorydata(prevState => {
                if (prevState.parent_id === id) {
                    return { ...prevState, parent_id: null };
                } else {
                    return { ...prevState, parent_id: id };
                }
            });
            onSubcategoryChange(id);
        };

        return (
            <>
                <ul style={{ marginLeft: "20px" }}  >
                    <li key={id}   > <input type="checkbox" value={id} onChange={handleCheckboxChange}
                        id={id} checked={Subcategorydata.parent_id === id} /> -{cat_name}
                    </li>

                    {subCategoryChecker && subcategory.map((item) => (

                        <MakeCategory {...item} key={item.id} onSubcategoryChange={handleSubcategoryChange} Subcategorydata={Subcategorydata}
                            setSubcategorydata={setSubcategorydata} />
                    ))}
                </ul>
            </>
        )
    }



    useEffect(() => {
        taxListHanler()
    }, [])



    const [expandedNodes, setExpandedNodes] = useState([]);

    const handleNodeToggle = (nodeId) => {
        if (expandedNodes.includes(nodeId)) {
            setExpandedNodes(expandedNodes.filter((node) => node !== nodeId));
        } else {
            setExpandedNodes([...expandedNodes, nodeId]);
        }
    };

    const renderTreeItems = (items) => {
        return items.map((item, index) => (
            <div className="d-flex  " style={{ borderBottom: "1px solid blue", width: "100%", minHeight: "50px" }}>

                <TreeItem
                    key={item.id}
                    nodeId={item.id.toString()}
                    label={item.cat_name}
                    onLabelClick={() => handleNodeToggle(item.id.toString())}
                    sx={{ margin: "10px", color: "black", width: "100%" }}
                >
                    {item.subcategory.length > 0 && (
                        <>
                            {renderTreeItems(item.subcategory.map((subItem) => subItem))}
                        </>
                    )}
                </TreeItem>
                <div className="d-flex" style={{ gap: "20px" }}>

                    {(item.parent_id > 0) ? <EditNoteTwoToneIcon sx={{ marginTop: "10px" }} onClick={() => editRedirect(item, "addsubcategory")} /> : <EditNoteTwoToneIcon sx={{ marginTop: "10px" }} onClick={() => editRedirect(item, "addcategory")} />}

                    {(item.parent_id > 0) && <DeleteIcon sx={{ marginTop: "10px", cursor: "pointer" }} onClick={() => deleteTax(item.id, item.parent_id)} />}

                </div>


            </div>
        ));
    };


    function handleClick(e) {
        setshow(e.target.name)
    }


    return (
        <>
            <ThemeMaker>
                <div>
                    <button className="btn btn-primary m-2" name="categorylist" onClick={(e) => handleClick(e)}>Category List</button>
                    <button className="btn btn-primary m-2" name="addcategory" onClick={(e) => handleClick(e)}>Add Category</button>
                    <button className="btn btn-primary m-2" name="addsubcategory" onClick={(e) => { handleClick(e); setsubCrete() }}>Add Sub Category</button>
                </div>

                {show == "categorylist" && <TreeView
                    aria-label="file system navigator"
                    defaultCollapseIcon={<ExpandMoreIcon />}
                    defaultExpandIcon={<ChevronRightIcon />}
                    expanded={expandedNodes}
                    onNodeToggle={(event, nodeIds) => setExpandedNodes(nodeIds)}
                    sx={{ width: "100%" }}
                >
                    {renderTreeItems(taxData)}
                </TreeView>}

                {show == "addcategory" && <>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label >Category name</label>
                                    <span className="text-danger" > * </span>
                                    {
                                        taxErrorData?.tax ? (
                                            <span className="text-danger tiny_size" > This field is required ! </span>
                                        ) : null
                                    }
                                    <input type="text" className="form-control" name="tax" placeholder="Category"
                                        value={tax.tax}
                                        onChange={(e) => setTax({ ...tax, [e.target.name]: e.target.value })}
                                        onBlur={(e) => errorHandler(e)}

                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group d-flex align-items-center" style={{ gap: "3px" }}>
                                    <label className="m-0">Status</label>
                                    <input type="checkbox"  name="status" placeholder="Name" checked={tax.status}
                                        value={tax?.status}
                                        onChange={(e) => setTax({ ...tax, [e.target.name]: !tax.status })}

                                    />
                                </div>
                            </div>
                            <div className="col-md-12">
                                {
                                    !isUpdate ? (
                                        <button type="submit" className="btn btn-primary"
                                            onClick={createTaxHandler}
                                        >
                                            Save
                                        </button>
                                    ) : (
                                        <button type="submit" className="btn btn-success"
                                            onClick={updateTax}
                                        >
                                            Update
                                        </button>
                                    )
                                }

                            </div>
                            {/* <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small> */}
                        </div>
                    </div>
                </>}

                {show == "addsubcategory" && <>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label >Sub Category name</label>
                                    <span className="text-danger" > * </span>
                                    {
                                        taxErrorData?.name ? (
                                            <span className="text-danger tiny_size" > This field is required ! </span>
                                        ) : null
                                    }
                                    <input type="text" className="form-control" name="cat_name" placeholder="Sub Category"
                                        value={Subcategorydata.cat_name}
                                        onChange={(e) => setSubcategorydata({ ...Subcategorydata, [e.target.name]: e.target.value })}
                                        onBlur={(e) => errorHandler(e)}
                                        style={{ width: "25%" }}
                                    />
                                </div>
                            </div>
                            <div className="col-md-8">
                                <div className="form-group">

                                    <div className="subcategory" style={{ maxHeight: "300px", overflowY: "scroll" }}>

                                        <div className="selectcategory" onClick={() => setSubcattoggle(!subcattoggle)}>--Select Category--<span style={{ "float": "right" }}>▼</span>
                                            {/* <input type="text" className="form-control" name="subcat" placeholder="Search" value={search} onChange={handleSearchChange} /> */}
                                        </div>

                                        {Nadata.map((item) => {
                                            return (
                                                <>
                                                    <MakeCategory {...item} onSubcategoryChange={handleSubcategoryChange} deleteTax={deleteTax} />
                                                </>
                                            )
                                        })}


                                    </div>

                                </div>
                            </div>
                            <div className="col-md-2">
                                <div className="form-group">
                                    <label >Status</label> <br />
                                    <input type="checkbox"  name="status" placeholder="Name" checked={Subcategorydata.status}
                                        value={Subcategorydata.status}
                                        onChange={(e) => setSubcategorydata({ ...Subcategorydata, [e.target.name]: !Subcategorydata.status })}
                                    />
                                </div>
                            </div>
                            <div className="col-md-12">
                                {
                                    !isUpdate && !listing ? (
                                        <button type="submit" className="btn btn-primary"
                                            onClick={createSubCatHandler}
                                        >
                                            Save
                                        </button>
                                    ) : (
                                        <button type="submit" className="btn btn-success"
                                            onClick={updateSubTax}
                                        >
                                            Update
                                        </button>
                                    )
                                }

                            </div>
                            {/* <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small> */}
                        </div>
                    </div>
                </>}

            </ThemeMaker >

        </>
    )
}

export default Addcategory;