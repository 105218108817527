import { Link } from "react-router-dom";
import { tokenDecode } from "../../../utils/helper";


const Sidebar = () => {
    const token: any = localStorage.getItem("us_st_d");

    const decoded: any = tokenDecode();
    let current_sellerid = decoded.data.user.seller_id;
    const { data } = decoded;
    const { permission: { category, order, product, role, seller, tax, user, location } } = data;
    // console.log("hhhh", decoded);

    if (decoded) {
        // if (isAdmin === "true") {
        //     return children;
        // }


    } else {
        console.log("no");

    }

    return (
        <>
            <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">
                <Link to="/dashboard" >
                    <a href="replace" className="sidebar-brand d-flex align-items-center justify-content-center" >
                        <div className="sidebar-brand-icon rotate-n-15">

                        </div>
                        <div className="sidebar-brand-text ">The seller app</div>
                    </a>
                </Link>
                <hr className="sidebar-divider my-0" />

                <li className="nav-item active">
                    <Link to="/dashboard" >
                        <a href="replace" className="nav-link" >
                            <i className="fas fa-fw fa-tachometer-alt"></i>
                            <span>Dashboard</span></a>
                    </Link>
                </li>

                <hr className="sidebar-divider" />

                <div className="sidebar-heading">
                    Management
                </div>
                <li className="nav-item">
                    {
                        seller?.read || seller?.write || tax?.write ? (
                            <>
                                <Link to="#" className="nav-link collapsed" data-toggle="collapse" data-target="#seller11"
                                    aria-expanded="true" aria-controls="collapseTwo" >
                                    <i className="fas fa-fw fa-cog"></i>
                                    <span>Sellers</span>
                                </Link>
                                <div id="seller11" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                                    <div className="bg-white py-2 collapse-inner rounded">
                                        {
                                            seller?.write ? (
                                                <Link to="/addseller" >
                                                    <a href="replace" className="collapse-item">Add seller</a>
                                                </Link>
                                            ) : null
                                        }
                                        {
                                            seller?.read ? (
                                                <Link to="/sellerlist" >
                                                    <a href="replace" className="collapse-item">Seller List</a>
                                                </Link>
                                            ) : null
                                        }
                                        {
                                            tax.write ? (
                                                <Link to="/tax" >
                                                    <a href="replace" className="collapse-item">Tax</a>
                                                </Link>
                                            ) : null
                                        }

                                    </div>
                                </div>
                            </>
                        ) : null
                    }
                    {
                        user?.read || user?.write || role?.write ? (
                            <>
                                <Link to="#" className="nav-link collapsed" data-toggle="collapse" data-target="#collapseOne"
                                    aria-expanded="true" aria-controls="collapseTwo" >
                                    <i className="fas fa-fw fa-cog"></i>
                                    <span>Users</span>
                                </Link>
                                <div id="collapseOne" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                                    <div className="bg-white py-2 collapse-inner rounded">
                                        {
                                            role?.write ? (
                                                <Link to="/roles" >
                                                    <a href="replace" className="collapse-item">Roles</a>
                                                </Link>
                                            ) : null
                                        }
                                        {
                                            user?.write ? (
                                                <Link to="/adduser" >
                                                    <a href="replace" className="collapse-item">Add user</a>
                                                </Link>
                                            ) : null
                                        }
                                        {
                                            user?.read ? (
                                                <Link to="/userlist" >
                                                    <a href="replace" className="collapse-item">User List</a>
                                                </Link>
                                            ) : null
                                        }

                                    </div>
                                </div>
                            </>
                        ) : null
                    }
                </li>
                <hr className="sidebar-divider" />

                <div className="sidebar-heading">
                    Interface
                </div>
                {
                    category?.write || product?.read || product?.write ? (
                        <>
                            <li className="nav-item">
                                <Link to="#" >
                                    <a className="nav-link collapsed" href="replace" data-toggle="collapse" data-target="#collapseTwo"
                                        aria-expanded="true" aria-controls="collapseTwo">
                                        <i className="fas fa-fw fa-cog"></i>
                                        <span>Products</span>
                                    </a>
                                </Link>
                                <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                                    <div className="bg-white py-2 collapse-inner rounded">
                                        {
                                            category?.write ? (
                                                <Link to="/category" >
                                                    <a href="replace" className="collapse-item">Category</a>
                                                </Link>
                                            ) : null
                                        }
                                        {
                                            category?.write ? (
                                                <Link to="/brand" >
                                                    <a href="replace" className="collapse-item">Brand</a>
                                                </Link>
                                            ) : null
                                        }

                                        {/* {
                                            product?.write ? (

                                                <a href="/masterproductlist" className="collapse-item">Master Product List</a>

                                            ) : null
                                        } */}

                                        {
                                            product?.write ? (

                                                <a href="/product" className="collapse-item">Add product</a>

                                            ) : null
                                        }
                                        {
                                            product?.read ? (
                                                <Link to="/productlist" >
                                                    <a href="replace" className="collapse-item">Product List</a>
                                                </Link>
                                            ) : null
                                        }
                                        {/* {current_sellerid == 1 && <>  <Link to="/mastercatalog" >
                                            <a href="replace" className="collapse-item">Add Catalog Product</a>
                                        </Link>
                                        </>

                                        } */}
                                        {/* {current_sellerid > 1 ? <>  <Link to="/sellernonapprovedproducts" >
                                            <a href="replace" className="collapse-item">Non-Approved Product</a>
                                        </Link>
                                        </> : <Link to="/nonapprovedproducts" >
                                            <a href="replace" className="collapse-item">Non-Approved Product</a>
                                        </Link>

                                        } */}

                                    </div>
                                </div>
                            </li>
                        </>
                    ) : null
                }
                {
                    order?.read ? (
                        <>
                            <li className="nav-item">
                                <Link to="#" >
                                    <a className="nav-link collapsed" href="replace" data-toggle="collapse" data-target="#collapseTwo_1"
                                        aria-expanded="true" aria-controls="collapseTwo">
                                        <i className="fas fa-fw fa-cog"></i>
                                        <span>Orders</span>
                                    </a>
                                </Link>
                                <div id="collapseTwo_1" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                                    <div className="bg-white py-2 collapse-inner rounded">
                                        {
                                            order?.read ? (
                                                <Link to="/orderlist" >
                                                    <a href="replace" className="collapse-item">Order List</a>
                                                </Link>
                                            ) : null
                                        }
                                        {
                                            order?.read ? (
                                                <Link to="/completedorders" >
                                                    <a href="replace" className="collapse-item">Completed Orders</a>
                                                </Link>
                                            ) : null
                                        }
                                        {
                                            order?.read ? (
                                                <Link to="/canceledorders" >
                                                    <a href="replace" className="collapse-item">Cancelled Orders</a>
                                                </Link>
                                            ) : null
                                        }
                                        {
                                            order?.read ? (
                                                <Link to="/returnedorders" >
                                                    <a href="replace" className="collapse-item">Return Orders</a>
                                                </Link>
                                            ) : null
                                        }
                                    </div>
                                </div>
                            </li>
                        </>
                    ) : null
                }
                {
                    order?.read ? (
                        <>
                            <li className="nav-item">
                                <Link to="#" >
                                    <a className="nav-link collapsed" href="replace" data-toggle="collapse" data-target="#collapseTwo1_1"
                                        aria-expanded="true" aria-controls="collapseTwo">
                                        <i className="fas fa-fw fa-cog"></i>
                                        <span>Transaction</span>
                                    </a>
                                </Link>
                                <div id="collapseTwo1_1" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                                    <div className="bg-white py-2 collapse-inner rounded">
                                        {
                                            order?.read ? (
                                                <Link to="/transaction" >
                                                    <a href="replace" className="collapse-item">Transaction</a>
                                                </Link>
                                            ) : null
                                        }
                                    </div>
                                </div>
                            </li>
                        </>
                    ) : null
                }
                {
                    (location?.read ) ? (
                        <>
                            <li className="nav-item">
                                <Link to="#" >
                                    <a className="nav-link collapsed" href="replace" data-toggle="collapse" data-target="#location"
                                        aria-expanded="true" aria-controls="collapseTwo">
                                        <i className="fas fa-fw fa-cog"></i>
                                        <span>Location</span>
                                    </a>
                                </Link>
                                <div id="location" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                                    <div className="bg-white py-2 collapse-inner rounded">
                                        {
                                            location?.read ? (
                                                <Link to="/location" >
                                                    <a href="replace" className="collapse-item">Add location</a>
                                                </Link>
                                            ) : null
                                        }
                                    </div>
                                </div>
                            </li>
                        </>
                    ) : null
                }

                <>
                    {/* <li className="nav-item">
                        <Link to="#" >
                            <a className="nav-link collapsed" href="replace" data-toggle="collapse" data-target="#shopdetails"
                                aria-expanded="true" aria-controls="collapseTwo">
                                <i className="fas fa-fw fa-cog"></i>
                                <span>Shop</span>
                            </a>
                        </Link>
                        <div id="shopdetails" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                            <div className="bg-white py-2 collapse-inner rounded">

                                <Link to="/shopdetails" >
                                    <a href="replace" className="collapse-item">Shop Details</a>
                                </Link>


                            </div>
                        </div>
                    </li> */}
                </>
                <hr className="sidebar-divider d-none d-md-block" />
            </ul>
        </>
    )
}

export default Sidebar;