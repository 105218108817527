import jwt_decode from "jwt-decode";
export const tokenDecode = (tk_access?: any) => {
    try {
        if (tk_access) {
            localStorage.setItem("us_st_d", tk_access);
        }
        const token: any = localStorage.getItem("us_st_d");
        const decoded: any = jwt_decode(token);
        if (decoded) {
            return decoded;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}