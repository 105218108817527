import React, { useState, useEffect } from 'react';
import styles from "./Dynamic.module.css"
import ThemeMaker from '../../components/thememaker';
import { toast } from "react-toastify";


export default function DragDropAttributes() {
    const [list1, setlist1] = useState([
        { fruits: ['Apple', 'Banana', 'Guava', 'Pomergranate', 'Pneapple', 'Orange', 'Kiwi', 'Strawberry'] },
        { cars: ['Lambo', 'Farari', 'Porche', 'Rolls Royce', 'Toyota', 'Tata', 'Porche', 'Mercedes', 'Wolkswagon'] },
        { dryfruits: ['Kaju', 'Kishmish', 'Badaam', 'Pista', 'Akhrot'] },
        { Nonvegs: ['Chiken Cuurry', 'Chiken Lolipop', 'Fried Chiken', 'Chiken Chilli', 'Chiken Handi'] },
    ]);
    const [list2, setlist2] = useState(['Potato', 'Tomato', 'Brinjle', 'Lady finger', 'Capsicum', 'Chilli', 'Bitter Gourd', 'Onion', 'Buggati', 'Mango', 'Kerala', 'Kabab', 'Tawa Chiken', 'Hyundai', 'Chiken Tikka']);

    const [createGroup, setcreateGroup] = useState(true)
    const [deleteGroup, setdeleteGroup] = useState(true)
    function dragStart(event) {
        event.dataTransfer.setData('Text', event.target.id);
    }

    function dragOverHandler(event, categorykey, targetIndex) {
        event.preventDefault();

        // for draging in same list-------------------------------->
        let Ldata = event.dataTransfer.getData('LText', event.target.id);
        let Lvalue = document.getElementById(Ldata)?.lastElementChild.innerText

        // for draging in from other list-------------------------------->
        let data = event.dataTransfer.getData('Text', event.target.id);
        let value = document.getElementById(data)?.innerText

        let newList = [...list1]

        newList.forEach(item => {
            Object.entries(item).map(([category, items]) => {
                console.log(category, items)
                if (category === categorykey && Ldata == "") {

                    if (!items.some(item => item == value)) {
                        items.push(value)
                    }

                } else if (category === categorykey && Ldata !== "") {
                    const z = items.indexOf(Lvalue)
                    items.splice(z, 1);
                    items.splice(targetIndex, 0, Lvalue);
                }
            })
        })
        setlist1([...newList]);
    }

    function allowDrop(event) {
        event.preventDefault();
    }

    function listDrag(event) {
        event.dataTransfer.setData('LText', event.target.id);
    }

    function deleteItemHandler(categoriy, iteim, index) {
        let newList = [...list1];

        newList.forEach(item => {
            Object.entries(item).map(([category, items]) => {
                if (category === categoriy) {
                    const z = items.indexOf(iteim)
                    items.splice(z, 1);
                }
            })
        })
        setlist1([...newList])
    }

    const [input, setInput] = useState("")
    const [attr, setattr] = useState("")

    function createGroupHandler(groupTitle) {
        console.log(input)
        setcreateGroup(true);
        let newList = [...list1]
        newList.push({ [groupTitle]: [attr] })
        setlist1([...newList])
        toast.success("Group Created")
    }

    function attributeSelectHandler(e) {
        setattr(e.target.value)
    }
    const [selected, setselected] = useState([])

    function categorySelectHandler(cat) {
        let z = [...selected]
        
        setselected(prev => {
            if (prev) {
                return [...prev, cat]
            }else {

            }

        })
    }



    function deleteSelectedHandler() {
        console.log("selected ----->", selected);
        let c = [...list1];
        let z = c.filter((item) => {
            const key = Object.keys(item)[0];
            console.log(!selected.includes(key))
            return !selected.includes(key);
        });

        console.log("opposite zzzzz", z);
        setlist1([...z])
        setdeleteGroup(true)
    }


    return (
        <ThemeMaker>

            <div style={{ display: 'flex', gap: '40px' }} className='w-100 p-5'>

                <div className='w-50' style={{ height: "80vh", overflowY: "scroll" }}>
                    <h4 className={styles.heading}>Group</h4>

                    {/* ----------------------------- Create group Section start --------------------------------------------------*/}
                    <div className='d-flex justify-content-start'>
                        {createGroup ? <div className={styles.buttonLikeDiv} onClick={() => setcreateGroup(false)}>Create New Group</div> : <div className='ml-1 d-flex align-items-center' style={{ gap: "2px" }}>
                            <input type="text" placeholder='Enter Group Name' onChange={(e) => setInput(e.target.value)} />
                            <select name="" id="" onChange={attributeSelectHandler} className='m-0' style={{ fontSize: "13px" }}>
                                <option value="">Select One Attribute</option>
                                {
                                    list2.map(item => {
                                        return <option value={item}>{item}</option>
                                    })
                                }
                            </select>
                            <button className='ml-1 px-2' onClick={() => createGroupHandler(input)}>Create</button>
                        </div>}
                        {deleteGroup ? <div className={styles.buttonLikeDiv} onClick={() => setdeleteGroup(false)}>Delete Groups</div> : <div className='ml-1 d-flex align-items-center' style={{ gap: "2px" }}>
                            <button className='btn btn-danger' onClick={deleteSelectedHandler}>Delete</button>
                        </div>}
                    </div>
                    {/* ----------------------------- Create group Section end --------------------------------------------------*/}

                    {/* ---------------------------- List 1 map Section Start ----------------------------------------------------  */}

                    {list1.map((item, index) => (
                        <div key={index} className={styles.tree}>
                            <ul
                                className={styles.unorderList}
                            >
                                {Object.entries(item).map(([category, items]) => (

                                    <li>
                                        {!deleteGroup && <input type="checkbox" name="" id="" onChange={() => categorySelectHandler(category)} />}  {category}
                                        <ul onDragOver={(event) => { allowDrop(event); }} >

                                            {items.map((item, index) => (
                                                <li key={index}
                                                    draggable
                                                    onDragStart={(event) => listDrag(event)}
                                                    id={`${category}-${index}`}
                                                    onDrop={(event) => dragOverHandler(event, category, index)}
                                                >
                                                    <i class="fa fa-minus-circle" style={{ color: "red", cursor: "pointer" }}
                                                        onClick={() => deleteItemHandler(category, item, index)}></i>
                                                    <span>{item}</span>
                                                </li>

                                            ))}

                                        </ul>
                                    </li>


                                ))}

                            </ul>
                        </div>
                    ))}

                    {/* ---------------------------- List 1 map Section end ----------------------------------------------------  */}
                </div>



                <div className='w-50' style={{ height: "80vh", overflowY: "scroll" }}>
                    <h4 className={styles.heading}>Unassigned Attributes</h4>

                    {/* ---------------------------- List 2 map Sectionstart ----------------------------------------------------  */}
                    {list2.map((item, index) => {
                        return (
                            <ul>
                                <li
                                    draggable
                                    id={`item${index}`}
                                    onDragStart={(event) => {
                                        dragStart(event);
                                    }}
                                >
                                    {item}
                                </li>
                            </ul>
                        );
                    })}

                    {/* ---------------------------- List 2 map Section end ----------------------------------------------------  */}
                </div>


            </div>


        </ThemeMaker>
    );
}
