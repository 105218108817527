import React, { useState, useEffect } from 'react'
import { toast } from "react-toastify";
import axios from "axios";
import ThemeMaker from '../../components/thememaker';
import { config } from "../../utils/config";

export default function Addattribute() {

    const [attrName, setattrName] = useState("")
    const [attrlist, setattrList] = useState([])
    const [showAttrlist, setshowAttrlist] = useState(false)
    const { apiEndpoint } = config;

    useEffect(() => {
        getAttributes()
    }, [])

    async function getAttributes() {
        const { data } = await axios({
            url: `https://staging.thesellerapp.com/admin/api/getattributelist`,
            method: "POST",

        })
        console.log(data)
        if (data) {
            setattrList(data.data)
        }

    }

    async function createAttrHandler() {
        const { data } = await axios({
            url: `https://staging.thesellerapp.com/admin/api/addattributeset`,
            method: "POST",
            data: {
                "name": attrName
            }
        })

        if (data) {
            setattrName("")
            getAttributes()
            toast.success("Attribute Created")
        } else {
            toast.error("Something Went Wrong")
        }
    }
    return (
        <ThemeMaker>
            <div className='pl-3' style={{minHeight: "70vh"}}>
                <h4>Create Addribute</h4>

                <div className=' d-flex align-items-center'>
                    <div className='d-flex justify-content-center align-items-start'>
                        <div style={{ width: "300px" }} className='p-0 m-0'>
                            <input type="text" placeholder='Attribute Name' onChange={(e) => setattrName(e.target.value)} className='p-0 m-0' style={{ width: "300px" }} onFocus={() => setshowAttrlist(true)} onBlur={() => setshowAttrlist(false)} />
                            {showAttrlist && <div className='' style={{height: "300px", overflowY: "scroll"}} >
                                {
                                    attrlist?.map(item => {
                                        return <div key={item.id}>
                                            <ul>
                                                <li style={{ listStyle: "none" }}>{item.attribute_label}</li>
                                            </ul>
                                        </div>
                                    })
                                }

                            </div>}
                        </div>

                        <button className='btn btn-success ml-2 px-2 py-1' onClick={createAttrHandler}>Create</button>

                    </div>

                    <div>
                       

                    </div>
                </div>

            </div>
        </ThemeMaker>
    )
}
