import { useState, useEffect, useRef } from "react";
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import ThemeMaker from "../../components/thememaker";
import axios from "axios";
import { tokenDecode } from "../../utils/helper";
import { config } from "../../utils/config";
//import { toast } from "react-toastify";
import styled from "styled-components"
import html2pdf from 'html2pdf.js';
import Invoice from "../Invoice/Invoice"
import { show, hide } from "react-functional-modal"
const { apiEndpoint } = config;

var $ = require('jquery');
const badgeMaker: any = {
    "Created": (order_state: any) => <span className="badge badge-primary">{order_state}</span>,
    "Shipped": (order_state: any) => <span className="badge badge-warning">{order_state}</span>,
    "Delivered": (order_state: any) => <span className="badge badge-success">{order_state}</span>,
    "Canceled": (order_state: any) => <span className="badge badge-danger">{order_state}</span>,
}
interface ICustomer {
    id: any;
}

const Pbutton = styled.button
    `
outline: none;
border: none;
margin-left: 18px;
background: #0094ff;
padding: 3px 5px;

`

const ProviderContainer = styled.div
    `
background: white;
width: 90%;
border: 1px solid rgb(0, 148, 255);
position: relative;
padding: 10px;
border-radius: 20px
`
const ListContainer = styled.div
    `
background: #e3e6f0;
width: 90%;
paddind: 10px;
padding: 10px;
font-size: 14px;
font-weight: 600;
border-radius: 20px
`

const OrderDetails = () => {
    let params: ICustomer = useParams();
    const { id: order_id } = params;

    const decoded: any = tokenDecode();
    const { data } = decoded;
    const {
        permission: { category, order, product, role, seller, tax, user },
    } = data;

    const [isLoading, setIsLoading] = useState(false);
    const [updateOrder_, setUpdateOrder_]: any = useState({
        order_id: "",
        tracking_url: "",
        order_state: ""
    })
    const [orderDetail, setorderDetail]: any = useState([]);
    const [_orderDetail, _setorderDetail]: any = useState({
        billing_address: "",
        contact_number: "",
        created_at: "",
        email: "",
        id: '',
        name: "",
        order_id: "",
        order_state: "",
        provider_id: "",
        shipping_address: "",
        status: "",
        total_price: 0
    });
    const [orderState, setOrderState] = useState({
        order_id,
        order_state: "",
        fulfillment_state: "",
        tracking_url: ""
    })
    const [logisticCharges, setlogisticCharges]: any = useState("")
    // const shipping_address = JSON.parse(_orderDetail?.shipping_address);


    const getorderDetails = async (order_id: any) => {
        setIsLoading(true);
        try {
            const { data } = await axios({
                url: `${apiEndpoint}/getsellerorder`,
                method: "POST",
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('us_st_d')}`
                },
                data: {
                    order_id
                }
            });
            console.log("order detail ->------   ", data);
            if (data.status === 200) {
                setIsLoading(false);
                setorderDetail(data.products);
                setlogisticCharges(JSON.parse(data.data[0]?.quote))
                let totalP = 0
                data.products.forEach((item: any) => {
                    totalP += parseFloat(item?.price)
                });
                setOrderState({ ...orderState, order_state: data.data[0]?.order_state, tracking_url: data.products[0]?.tracking_url, fulfillment_state: data.products[0]?.fulfillment_state })
                setUpdateOrder_({
                    ...updateOrder_,
                    order_id: data.data[0]?.order_id,
                    tracking_url: data.data[0]?.tracking_url,
                    order_state: data.data[0]?.order_state
                })
                _setorderDetail({ ..._orderDetail, ...data.data[0], total_price: data.data[0].order_total, billing_address: JSON.parse(data.data[0]?.billing), shipping_address: JSON.parse(data.data[0]?.fulfillments), quote: JSON.parse(data.data[0].quote) });


            }
        } catch (error) {
            console.log("error", error);
            setIsLoading(false);
        }
    }
    const updateOrder = async (product_id: any) => {
        try {
            console.log(orderDetail);
            const getItem = orderDetail.find((item: any) => item?.product_id === product_id);
            console.log("getItem", getItem);

            const { data } = await axios({
                url: `${apiEndpoint}/updateitemstatus`,
                method: "POST",
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('us_st_d')}`
                },
                data: getItem
            });
            console.log(data, "datadata");

            if (data.message === "flag1") {
                toast.success("Item status updated !", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                toast.error("Something went wrong !", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }

        } catch (error) {
            console.log("yess", error);

            // console.log("update error", error);
            // toast.error("404 error !", {
            //     position: "top-right",
            //     autoClose: 5000,
            //     hideProgressBar: false,
            //     closeOnClick: true,
            //     pauseOnHover: true,
            //     draggable: true,
            //     progress: undefined,
            // });

            // window.location.reload();

        }
    }
    const updateOrderChangeHandler = (e: any, index: any) => {
        // setUpdateOrder_({ ...updateOrder_, [e.target.name]: e.target.value })
        console.log(e.target.value, "index---", e.target.name, index);
        const newUpdate = orderDetail;
        newUpdate[index][e.target.name] = e.target.value;
        console.log(newUpdate, "newUpdate");

        setorderDetail([...newUpdate]);

    }
    const updateThisOrder = async () => {
        try {
            console.log(orderState);
            const { data } = await axios({
                url: `${apiEndpoint}/updateorderstatus`,
                method: "POST",
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('us_st_d')}`
                },
                data: orderState
            });
            console.log(data, "datadata");

            if (data.message === "flag1") {
                toast.success("Order status updated !", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                toast.error("Something went wrong !", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (error) {
            toast.error("Something went wrong 500 !", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }
    const [cancelpopup, setcancelpopup] = useState(false);
    const cancelbtn = () => {
        setcancelpopup(true);
        // console.log("cancelPopup");
    };

    const [cancelreson, setcancelreson] = useState("");
    const changeReturnStatus = (selectedStatus: any) => {
        // console.log("selectedStatus", selectedStatus);
        // istatus = selectedStatus;
        setcancelreson(selectedStatus);
    };

    const ordercancel = async () => {
        // console.log("cancel", cancelreson);
        // console.log("orderCancel hit");

        const statusData = {
            order_id: order_id,
            reason_code: cancelreson,
        };
        const id = toast.loading("Please wait...");
        try {
            const response = await axios({
                method: "post",
                // url: "https://shikhar-preprod.herokuapp.com/v1/unsolicated_cancel",
                url: "https://staging.thesellerapp.com/v1/unsolicated_cancel",
                headers: { Authorization: `Bearer ${localStorage.getItem("us_st_d")}` },
                data: statusData,
            })
                .then(function (response) {
                    // console.log("response 173", response);
                    toast.update(id, {
                        render: "Status Updating",
                        type: "success",
                        isLoading: false,
                        autoClose: 2000,
                    });
                    setcancelpopup(false);
                })
                .catch(function (error) {
                    console.log("error 176", error);
                    toast.update(id, {
                        render: "something worng happned",
                        type: "error",
                        isLoading: false,
                        autoClose: 2000,
                    });
                })
                .finally(function () {
                    // console.log("response   // always executed 179");
                });
        } catch (error) {
            console.error(" error 784", error);
        }
    };

    const [showPop, setShowPop] = useState(false);
    const toggle = (e: any) => {
        e.preventDefault();
        setShowPop(true);
    };

    const cancleQty = (item: any, setCancelvalue: any) => {
        item.qty = setCancelvalue;
    };

    const cancleReason = (item: any, setCancelPartialreson: any) => {
        item.reason = setCancelPartialreson;
    };

    const [orderCancle, setOrderCancle]: any = useState({
        order_id,
        order_status: "Created",
        product_id: [],
    });

    const [cancelvalue, setCancelvalue]: any = useState("");
    const [cancelPartialreson, setCancelPartialreson]: any = useState("");


    const cancleChecked = (item: any, setisChecked: any) => {
        item.Checked = setisChecked;
    };
    const cancleUpdate = (
        cancelvalue: any,
        cancelPartialreson: any,
        item: any,
        item_id: any,
        order_status: any
    ) => {
        const newrawdata = [
            {
                item_id: item_id,
                order_status: order_status,
                cancel_quantity: item.qty,
                reason_code: item.reason,
            },
        ];
        orderCancle.product_id = Object.assign({}, newrawdata);
    };

    const cancelPartial = async (e: any) => {
        try {
            // console.log("orderState---->", orderState);
            const response = await axios({
                url: `${apiEndpoint}/sellerordercancel`,
                method: "POST",
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
                },
                data: orderCancle,
            });
            if (response.status === 200) {
                toast.success("Order cancel updated !", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                // console.log("response for cancelation", response);
                toast.error("Something went wrong !", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (error) {
            console.error(" error 784", error);
            toast.error("Something went wrong !", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };

    // ----------------------------------------Code functions for Download invoice start-----------------------------------------
    function PDFGenerator() {
        const element = document.getElementById('page-content'); // ID of the element containing the page content
        html2pdf().set({ margin: 0, filename: `Invoice - ${order_id}`, image: { type: 'jpeg', quality: 1.0 } }).from(element).save();
    }


    // ----------------------------------------Code functions for Download invoice end-----------------------------------------



    // ---------------------------------- Code functions for logistics Start------------------------------------------------

    const [selectProvider, setselectProvider]: any = useState(null);
    const [providerPayload, setproviderPayload]: any = useState({
        transaction_id: "",
        message_id: ""
    })
    const [providersData, setprovidersData]: any = useState([])
    const [submitData, setSubmitData]: any = useState([])
    const controller = useRef(false)
    interface Item {
        id: number;
        checked: boolean;
    }
    const [issChecked, setIssChecked] = useState<Item[]>([]);
    const [isinitRunning, setisinitRunning] = useState(false);
    const [WeightSection, setWeightSection] = useState(false);

    const [wAndD, setwAndD] = useState({
        weight: "",
        weightUnit: "",
        length: "",
        width: "",
        height: "",
        dimensionUnit: ""
    })



    useEffect(() => {
        getProviders()
    }, [providerPayload])
    useEffect(() => {
        if (controller.current !== false) {
            open()
        }


    }, [submitData])

    function WandChangeHandler(e: any) {
        setwAndD((prev) => {
            return { ...prev, [e.target.name]: e.target.value }
        })
    }

    async function getProvidersPayload() {
        console.log("form data", wAndD)
        let dataTosend = {
            order_id: order_id,
            weight: {
                unit: wAndD.weightUnit,
                value: wAndD.weight
            },
            dimensions: {
                length: {
                    unit: wAndD.dimensionUnit,
                    value: wAndD.weight
                },
                "breadth": {
                    unit: wAndD.dimensionUnit,
                    value: wAndD.width
                },
                "height": {
                    unit: wAndD.dimensionUnit,
                    value: wAndD.height
                }
            }
        }
        setselectProvider(false)
        const { data } = await axios({
            url: `https://preprod.xpressbaazaar.com/adapter/logistics/search`,
            method: "POST",
            headers: {
                Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
            },
            data: dataTosend
        })
        console.log("search--->", data)
        if (data?.data) {
            const { transaction_id, message_id } = data.data.context
            setproviderPayload((prev: any) => {
                return { ...prev, transaction_id: transaction_id, message_id: message_id }
            })


        }
    }

    async function getProviders() {
        const { data } = await axios({
            url: `http://preprod.xpressbaazaar.com/adapter/logistics/providers`,
            method: "POST",
            headers: {
                Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
            },
            data: providerPayload
        })
        console.log("provider--->", data)
        if (data.message == "flag1" && data?.data.length > 0) {
            setprovidersData(data.data)
            setselectProvider(true)
        }

        console.log(data)
    }

    async function selectLogisticProvider(item: any, itemId: any) {

        setSubmitData(item);
        setIssChecked((prevItems: any) =>
            prevItems.map((item: any) =>
                item.id === itemId ? { ...item, checked: true } : { ...item, checked: false }
            )
        );

    }



    async function submitSelectedProvider() {
        console.log(submitData)
        const { transaction_id, message_id, bpp_id, bpp_uri, descripter, bpp_provider_id, item } = submitData
        const { data } = await axios({
            url: `http://preprod.xpressbaazaar.com/adapter/logistics/init`,
            method: "POST",
            headers: {
                Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
            },
            data: {
                "order_id": order_id,
                "transaction_id": transaction_id,
                "message_id": message_id,
                "bpp_id": bpp_id,
                "bpp_uri": bpp_uri,
                "descripter": descripter,
                "bpp_provider_id": bpp_provider_id,
                "category_id": item.category_id,
                "items_id": item.id,
                "descriptor": item.descriptor.code,
                "fulfillment_id": item.fulfillment_id

                // "order_id": "2023-05-30-676472",
                // "transaction_id": "b1f11486-6a48-422b-aaf3-e6d98bfbffbf",
                // "message_id": "9569f153-9933-4046-a8f9-347b7fad9e20",
                // "bpp_id": "qaapis.delcaper.com",
                // "bpp_uri": "https://qaapis.delcaper.com/public/ondc",
                // "descripter": "Delcaper",
                // "descriptor": "P2P",
                // "bpp_provider_id": "Delcaper",
                // "category_id": "Same Day Delivery",
                // "items_id": "1",
                // "fulfillment_id": 1

            }
        })
        // hide("key#1")
        console.log("init response---->", data)

        setTimeout(() => {
            console.log("timer function")
            hide("key#1")
            confirmModal()
        }, 1000);
    }


    const open = () => {

        controller.current = true
        show(
            <ProviderContainer>
                <div className="d-flex flex-column align-items-center" >
                    <p style={{ position: "absolute", top: "5px", right: "10px", color: "black" }} onClick={() => {
                        hide("key#1")
                    }}>x</p>
                    <h3 className="text-center mt-2" style={{ fontSize: "17px", color: "balck", fontWeight: "600" }}>Providers</h3>

                    <div className="w-100 d-flex flex-column align-items-center" style={{ gap: "13px", maxHeight: "320px", overflow: "scroll" }}>
                        {
                            providersData?.map((item: any, index: any) => {
                                if (index >= issChecked.length) {
                                    setIssChecked(prevState => [
                                        ...prevState,
                                        { id: index, checked: false }
                                    ]);
                                }
                                return <ListContainer key={index}>
                                    <p style={{ color: "black" }}>Name: {item.bpp_descriptor_name}</p>
                                    <p style={{ color: "black" }}>Category: {item.item.category_id}</p>
                                    <div style={{ color: "black" }} className="d-flex justify-content-between">Price:Rs{item.item.price.value}
                                        <section>
                                            <label htmlFor="" style={{
                                                display: "inline-block",
                                                marginLeft: "5px",
                                                color: "black",
                                            }}>Select</label>
                                            <input type="radio" name="choose"
                                                id=""
                                                onChange={() => { selectLogisticProvider(item, index) }} style={{
                                                    appearance: "none", marginRight: "5px",
                                                    position: "relative",
                                                    top: "2px",
                                                    width: "13px",
                                                    height: "13px",
                                                    borderRadius: "50%",
                                                    border: "1px solid blue",
                                                    backgroundColor: issChecked[index]?.checked ? "blue" : "transparent",
                                                    outline: "none",
                                                    marginLeft: "3px"
                                                }}
                                            />
                                        </section> </div>

                                    {/* checked={selectedItems[item.item.id] || false} */}
                                </ListContainer>
                            })
                        }

                    </div>
                </div>
                <Pbutton onClick={submitSelectedProvider} className="my-2">Submit</Pbutton>

            </ProviderContainer>,
            {
                key: "key#1",
                fading: true,

            }
        )
    }

    const onConfirmHandler = async (cdata: any) => {
        const { transaction_id, message_id, bpp_id, bpp_uri, descripter, bpp_provider_id, item } = submitData

        const { data } = await axios({
            url: "http://preprod.xpressbaazaar.com/adapter/logistics/confirm",
            method: "POST",
            headers: {
                Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
            },
            data: {

                "transaction_id": transaction_id,
                "message_id": message_id,
                "bpp_id": bpp_id,
                "bpp_uri": bpp_uri,
                "order_id": order_id

                // "order_id": "2023-05-30-676472",
                // "transaction_id": "b1f11486-6a48-422b-aaf3-e6d98bfbffbf",
                // "message_id": "9569f153-9933-4046-a8f9-347b7fad9e20",
                // "bpp_id": "qaapis.delcaper.com",
                // "bpp_uri": "https://qaapis.delcaper.com/public/ondc",
                // "descripter": "Delcaper",
                // "descriptor": "P2P",
                // "bpp_provider_id": "Delcaper",
                // "category_id": "Same Day Delivery",
                // "items_id": "1",
                // "fulfillment_id": 1

            }
        })

        if (data?.data?.message?.ack?.status == "ACK") {
            toast.success("Provider Confirmed !", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            hide("key#2")
        } else {
            toast.error("Something went wrong !", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    const confirmModal = () => {
        const textStyle1 = {
            fontWeight: "600", fontSize: "17px", color: "black"
        }
        show(
            <ProviderContainer>
                <div className="d-flex flex-column align-items-center " style={{ height: "200px" }} >
                    <p style={{ position: "absolute", top: "5px", right: "10px", color: "black" }} onClick={() => {
                        hide("key#2")
                    }}>x</p>
                    <h3 className="text-center mt-2" style={{ fontSize: "17px", color: "balck", fontWeight: "600" }}>Confirm Provider</h3>

                    <div className="w-100 d-flex flex-column " style={{ gap: "6px", maxHeight: "320px", overflow: "scroll" }}>

                        <p className="p-0 m-0"> <span style={textStyle1}> Provider Name:</span> <span className="ml-3"> {submitData?.descripter}</span></p>
                        <p className="p-0 m-0"> <span style={textStyle1}>Category: </span> <span className="ml-3">{submitData?.item?.category_id}</span> </p>
                        <p className="p-0 m-0"> <span style={textStyle1}>Price:</span>  <span className="ml-2">Rs. {submitData?.item?.price?.value}</span>  </p>

                    </div>
                    <Pbutton onClick={() => { onConfirmHandler(submitData) }} className="mt-4">Confirm</Pbutton>
                </div>
            </ProviderContainer>,
            {
                key: "key#2",
                fading: true,

            }
        )
    }



    // ---------------------------------- Code functions for logistics End------------------------------------------------


    console.log("dsfghjkjdsdsdasdsjdsdsd", selectProvider)

    useEffect(() => {
        getorderDetails(order_id);
    }, [])
    return (
        <>
            <ThemeMaker>
                <div className="row">
                    <div className="col-md-12 pl-4 pr-4" style={{ fontSize: "14px" }} >
                        <div className="card shadow mb-4">
                            <div className="card-header py-3 d-flex justify-content-between">
                                <h6 className="m-0 font-weight-bold text-primary">
                                    Order Detail
                                </h6>

                                <button className="btn btn-primary" onClick={PDFGenerator}>Download Invoice</button>

                                <div style={{ display: "none" }}>
                                    <Invoice orderId={order_id} />
                                </div>
                            </div>

                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLongTitle">Order id: {_orderDetail?.order_id}</h5>


                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-md-5">
                                        <p> Product name </p>
                                    </div>
                                    <div className="col-md-3">
                                        <p className="text-center"> Price x quantity </p>
                                    </div>
                                    <div className="col-md-3">
                                        <p className="text-center"> Price  </p>
                                    </div>
                                    {/* <div className="col-md-5 d-flex justify-content-between">
                                        <p> Fulfillment Status  </p>
                                        <p> Tracking Url  </p>
                                        <p> Action  </p>
                                    </div> */}
                                </div>
                                {
                                    orderDetail.map((_iitm: any, index: any) => {
                                        return (

                                            <div className="row mt-1">
                                                <div className="col-md-5" >
                                                    <p> <span>{index + 1}. </span>
                                                        {_iitm?.product_name}
                                                    </p>
                                                </div>
                                                <div className="col-md-3" >
                                                    <p className="text-center"> {_iitm?.price} x {_iitm?.quantity}</p>
                                                </div>
                                                <div className="col-md-3" >
                                                    <p className="text-center"> {(_iitm?.price) * _iitm?.quantity} </p>
                                                </div>
                                                {/* <div className="col-md-5 d-flex">

                                                    <select value={_iitm?.fulfillment_state} className="form-control" name="fulfillment_state"
                                                        onChange={(e) => updateOrderChangeHandler(e, index)}
                                                    >
                                                        <option value="Serviceable">Serviceable</option>
                                                        <option value="Order-picked-up">Order-picked-up</option>
                                                        <option value="Out-for-delivery">Out-for-delivery</option>
                                                        <option value="Order-delivered">Order-delivered</option>
                                                        <option value="RTO-Initiated">RTO-Initiated</option>
                                                        <option value="RTO-Delivered">RTO-Delivered</option>
                                                        <option value="Cancelled">Cancelled</option>

                                                    </select>
                                                    <input type="text" value={_iitm?.tracking_url} name="tracking_url" className="form-control" id=""
                                                        onChange={(e) => updateOrderChangeHandler(e, index)}
                                                    />
                                                    <button
                                                        className="btn btn-success ml-2"
                                                        onClick={() => updateOrder(_iitm?.product_id)}
                                                    >Save</button>
                                                </div> */}
                                            </div>
                                        )
                                    })
                                }
                                <div className="row alert alert-info">
                                    <div className="col-md-6" >
                                        <p>
                                            Total
                                        </p>
                                    </div>
                                    <div className="col-md-3" >
                                        <p> </p>
                                    </div>
                                    <div className="col-md-3" >
                                        <p> {_orderDetail?.total_price}  </p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-5 card ml-5 mb-3" >
                                        <p className="font-weight-bold" > Contact Information </p>
                                        <p>Name : {_orderDetail?.name}</p>
                                        <p>Email : {_orderDetail?.email}</p>
                                        <p> Phone : {_orderDetail?.contact_number}</p>

                                    </div>
                                    <div className="col-md-5 card ml-5 mb-3" >
                                        <p className="font-weight-bold" > Order Details </p>
                                        <p >Order id: {_orderDetail?.order_id}</p>
                                        <p >Bill date: {_orderDetail?.billing_address?.created_at?.substring(0, 10)}</p>
                                        <p >Delivery Charges: {_orderDetail?.quote?.breakup[logisticCharges?.breakup.length-2]?.price?.value} Rs</p>
                                        <p >Packing Charges: {_orderDetail?.quote?.breakup[logisticCharges?.breakup.length-1]?.price?.value} Rs</p>
                                        <p >Total Amount: {_orderDetail?.order_total} Rs</p>
                                    </div>
                                    <div className="col-md-5 card ml-5 mb-3" >
                                        <p className="font-weight-bold" > Billing Address </p>
                                        <p>Door : {_orderDetail?.billing_address?.address?.door}</p>
                                        <p>Name : {_orderDetail?.billing_address?.address?.name}</p>
                                        <p>Building : {_orderDetail?.billing_address?.address?.building}</p>
                                        <p>Street : {_orderDetail?.billing_address?.address?.street}</p>
                                        <p>Locality : {_orderDetail?.billing_address?.address?.locality}</p>
                                        <p>Ward : {_orderDetail?.billing_address?.address?.ward}</p>
                                        <p>City : {_orderDetail?.billing_address?.address?.city}</p>
                                        <p>State : {_orderDetail?.billing_address?.address?.state}</p>
                                        <p>Country : {_orderDetail?.billing_address?.address?.country}</p>
                                        <p>Area code : {_orderDetail?.billing_address?.address?.area_code}</p>
                                        <p>Phone : {_orderDetail?.billing_address?.phone}</p>
                                        <p>Email : {_orderDetail?.billing_address?.email}</p>

                                    </div>
                                    <div className="col-md-5 card ml-5 mb-3" >
                                        <p className="font-weight-bold" > Shipping Address </p>
                                        <p>Door : {_orderDetail?.shipping_address[0]?.end?.location?.address?.door}</p>
                                        <p>Name : {_orderDetail?.shipping_address[0]?.end?.location?.address?.name}</p>
                                        <p>Building : {_orderDetail?.shipping_address[0]?.end?.location?.address?.building}</p>
                                        <p>Street : {_orderDetail?.shipping_address[0]?.end?.location?.address?.street}</p>
                                        <p>Locality : {_orderDetail?.shipping_address[0]?.end?.location?.address?.locality}</p>
                                        <p>Ward : {_orderDetail?.shipping_address[0]?.end?.location?.address?.ward}</p>
                                        <p>City : {_orderDetail?.shipping_address[0]?.end?.location?.address?.city}</p>
                                        <p>State : {_orderDetail?.shipping_address[0]?.end?.location?.address?.state}</p>
                                        <p>Country : {_orderDetail?.shipping_address[0]?.end?.location?.address?.country}</p>
                                        <p>Area code : {_orderDetail?.shipping_address[0]?.end?.location?.address?.area_code}</p>
                                        <p>Phone : {_orderDetail?.shipping_address[0]?.end?.contact?.phone}</p>
                                        <p>Email : {_orderDetail?.shipping_address[0]?.end?.contact?.email}</p>
                                    </div>
                                </div>

                               {order.update ?  <div >
                                    <div className=" d-flex">
                                        <div className="col-md-6">
                                            <label htmlFor=""> Order status </label>
                                            <select value={orderState?.order_state} className="form-control  col-8" name="order_state"
                                                onChange={(e) => setOrderState({ ...orderState, [e.target.name]: e.target.value })}
                                            >
                                                <option value="Created">Created</option>
                                                <option value="Accepted">Accepted</option>
                                                <option value="In-progress">In-progress</option>
                                                <option value="Completed">Completed</option>
                                                <option value="Cancelled">Cancelled</option>
                                                {/* <option value="Returned">Returned</option> */}
                                            </select>
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor=""> Fulfillment status </label> <br />
                                            <select
                                                className=" col-8 form-control"
                                                aria-label="size 3 select example"
                                                value={orderState?.fulfillment_state}
                                                name="fulfillment_state"
                                                onChange={(e) => {
                                                    setOrderState({
                                                        ...orderState,
                                                        [e.target.name]: e.target.value,
                                                    })
                                                    console.log("fullfilment state----", e.target.value)
                                                }
                                                }
                                            >
                                                <option value="">Select Fulfillment Status</option>
                                                {(orderState?.order_state == "Created" || orderState?.order_state == "Accepted") && <> <option value="Pending">Pending</option> </>}

                                                {(orderState?.order_state == "In-progress") && <>
                                                    <option value="Packed">Packed</option>
                                                    <option value="Order-picked-up">Order-picked-up</option>
                                                    <option value="Out-for-delivery">Out-for-delivery</option>

                                                </>}


                                                {(orderState?.order_state == "Completed") && <>
                                                    <option value="Order-delivered">Order-delivered</option>
                                                </>}


                                                {(orderState?.order_state == "Cancelled") && <>
                                                    <option value="Cancelled">Cancelled</option>
                                                </>}
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-md-12 mt-2">
                                        <label htmlFor=""> Tracking Url </label>
                                        <input type="text" value={orderState?.tracking_url} name="tracking_url" className="form-control col-md-6" id=""
                                            onChange={(e) => setOrderState({ ...orderState, [e.target.name]: e.target.value })}
                                        />
                                    </div>


                                </div> : null}

                             { order.update ?  <div>
                                    <div className="m-2 ">

                                        <button className="btn btn-success m-2"
                                            onClick={updateThisOrder}
                                        >Update</button>

                                        <button
                                            onClick={cancelbtn}
                                            className="btn btn-outline-danger m-2"
                                        >
                                            Order Cancel
                                        </button>

                                        <button
                                            className="btn btn-primary m-2"
                                            onClick={(e) => {
                                                toggle(e);
                                            }}
                                        >
                                            Partial Cancelation
                                        </button>

                                    </div>

                                    <div>
                                        {/* --------------HTML CODE FOR  CANCEL POP UP START----------------------------- */}
                                        {cancelpopup ? (
                                            <>
                                                <div onClick={() => setcancelpopup(false)}
                                                    className={`cancelpopupoverlay`}></div>
                                                <div className={`cancelorderpopup p-3 `}>
                                                    <div className="row">
                                                        <div className="col-12 ">
                                                            <select className="btn btn-outline-dark w-100 text-left"
                                                                value={cancelreson} onChange={(e) =>
                                                                    changeReturnStatus(e.target.value)
                                                                }>
                                                                <option value="">
                                                                    Select Cancel reason
                                                                </option>
                                                                <option value="002">
                                                                    One or more items in the Order not available
                                                                </option>
                                                                <option value="005">
                                                                    Merchant rejected the order
                                                                </option>
                                                                {/* <option value="008">
                                Order not ready for pickup
                              </option> */}
                                                                <option value="011">
                                                                    Buyer not found or cannot be contacted
                                                                </option>
                                                                <option value="013">
                                                                    Buyer refused to accept delivery
                                                                </option>
                                                                <option value="014">Address not found</option>
                                                                <option value="015">
                                                                    Buyer not available at location
                                                                </option>
                                                                {/* <option value="016">
                                Accident / rain / strike / vehicle issues
                              </option> */}
                                                                {/* <option value="017">
                                Order delivery delayed or not possible
                              </option> */}
                                                                <option value="018">
                                                                    Delivery pin code not serviceable
                                                                </option>
                                                                <option value="019">
                                                                    Pickup pin code not serviceable
                                                                </option>
                                                                <option value="020">
                                                                    Order lost in transit
                                                                </option>
                                                            </select>
                                                            <p className="text-center mt-3 d-none">{`You selected : ${cancelreson}`}</p>
                                                        </div>
                                                        <div className="col-12 m-2 p-2 d-flex justify-content-between">
                                                            <button
                                                                className="btn btn-outline-dark col-5 col-md-5"
                                                                onClick={() => setcancelpopup(false)}
                                                            >
                                                                Close
                                                            </button>
                                                            <button
                                                                className="btn btn-outline-danger col-5 col-md-5"
                                                                onClick={() => ordercancel()}
                                                            >
                                                                Cancel Order
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        ) : null}
                                        {/* --------------HTML CODE FOR  CANCEL POP UP END----------------------------- */}


                                        {/* --------------HTML CODE FOR PARTIAL CANCEL POP UP START----------------------------- */}
                                        {showPop ? (
                                            <>
                                                <div
                                                    onClick={() => setShowPop(false)}
                                                    className={`cancelpopupoverlay`}
                                                ></div>
                                                <div className={`popup `}>
                                                    {orderDetail &&
                                                        orderDetail.map((item: any, index: any) => {
                                                            return (
                                                                <>
                                                                    <div className="row p-4 mb-2" key={index}>
                                                                        <div className="col-12 mb-2">
                                                                            Product Name: {item.product_name}
                                                                        </div>
                                                                        <div className="col-md-6 col-sm-12">
                                                                            Current Quantity: {item.quantity}
                                                                        </div>
                                                                       
                                                                        <div className="row">
                                                                            <div className="col-12 pl-4" key={index}>
                                                                                <label className="col-form-label">
                                                                                    Cancel Reason:
                                                                                </label>
                                                                                <select className=" ml-2 text-left" name="reason" value={item.reason}
                                                                                   
                                                                                    onChange={(e) => {
                                                                                        cancleReason(item, e.target.value);

                                                                                    }}
                                                                                >
                                                                                    <option value="001">
                                                                                        Select Reason
                                                                                    </option>
                                                                                    <option value="001">
                                                                                        Price of one or more items have
                                                                                    </option>
                                                                                    <option value="002">
                                                                                        One or more items in the Order not available
                                                                                    </option>
                                                                                    <option value="003">
                                                                                        Product available at lower than order price
                                                                                    </option>
                                                                                    <option value="004">
                                                                                        Order in pending shipment / delivery state for too long
                                                                                    </option>
                                                                                    <option value="005">
                                                                                        Merchant rejected the order
                                                                                    </option>
                                                                                    <option value="006">
                                                                                        Order not shipped as per buyer app SLA
                                                                                    </option>
                                                                                    <option value="008">
                                                                                        Order not ready for pickup
                                                                                    </option>
                                                                                    <option value="009">
                                                                                        Wrong product delivered
                                                                                    </option>
                                                                                    <option value="010">
                                                                                        Buyer wants to modify details
                                                                                    </option>
                                                                                    <option value="011">
                                                                                        Buyer not found or cannot be contacted
                                                                                    </option>
                                                                                    <option value="012">
                                                                                        Buyer does not want product any more
                                                                                    </option>
                                                                                    <option value="013">
                                                                                        Buyer refused to accept delivery
                                                                                    </option>
                                                                                    <option value="014">
                                                                                        Address not found
                                                                                    </option>
                                                                                    <option value="015">
                                                                                        Buyer not available at location
                                                                                    </option>
                                                                                    <option value="016">
                                                                                        Accident / rain / strike / vehicle issues
                                                                                    </option>
                                                                                    <option value="017">
                                                                                        Order delivery delayed or not possible
                                                                                    </option>
                                                                                    <option value="018">
                                                                                        Delivery pin code not serviceable
                                                                                    </option>
                                                                                    <option value="019">
                                                                                        Pickup pin code not serviceable
                                                                                    </option>
                                                                                </select>
                                                                                {/* <p className="text-center mt-3 d-none">{`You selected : ${cancelPartialreson}`}</p> */}
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-sm-12" key={index}>
                                                                            <label className="col-form-label">
                                                                                Cancel Quantity:
                                                                            </label>
                                                                            <input
                                                                                type="number"
                                                                                style={{
                                                                                    border: "none",
                                                                                    borderBottom: "1px solid #ccc",
                                                                                }}
                                                                                name="qty"
                                                                                min={1}
                                                                                max={item.quantity}
                                                                                value={item.qty}
                                                                                onChange={(e) => {
                                                                                    cancleQty(item, e.target.value);
                                                                                    // setCancelvalue({[e.target.name]:e.target.value})
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        <div className="row d-flex align-items-baseline">
                                                                            <div className="col-6 ml-3 d-flex align-items-baseline">
                                                                                Update &nbsp;
                                                                                <input
                                                                                    type="checkbox"
                                                                                    name="item_id"
                                                                                    value={item.checked}
                                                                                    onChange={(e) => {
                                                                                        cancleChecked(item, e.target.value);
                                                                                        cancleUpdate(
                                                                                            cancelvalue,
                                                                                            cancelPartialreson,
                                                                                            item,
                                                                                            item.product_id,
                                                                                            orderState.order_state
                                                                                        );
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                            <div className="col-6 justify-content-end position-relative p-2">
                                                                                <button
                                                                                    className="btn btn-primary ml-3 align-end"
                                                                                    type="submit"
                                                                                    onClick={(e) => cancelPartial(e)}
                                                                                >
                                                                                    Cancel
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            );
                                                        })}

                                                    <div className="col-12 d-flex justify-content-end position-relative p-2">
                                                        <button
                                                            className="btn btn-primary align-end"
                                                            onClick={(e) => setShowPop(false)}
                                                        >
                                                            Done
                                                        </button>
                                                    </div>
                                                </div>
                                            </>
                                        ) : null}
                                        {/* ---------------------HTML CODE FOR PARTIAL CANCEL POP UP END----------------------------- */}
                                    </div>


                                    {/* ----------------------------------------HTML for Logistics Part Start ----------------------------------------------- */}
                                    <div className="m-2 p-2">
                                        <strong className="text-info">Choose Logistics</strong>

                                        <div>
                                            <input type="radio" name="logistics" onChange={() => setWeightSection(false)} /> <label htmlFor=""> Off Network </label>
                                        </div>
                                        <div className="d-flex align-items-center" style={{ gap: "2px" }}>
                                            <input type="radio" name="logistics" onChange={() => setWeightSection(true)} /> <label htmlFor="" className="m-0"> On Network </label>
                                            {selectProvider ? <Pbutton onClick={open}>Get Providers</Pbutton> : (selectProvider == false) ? "Loading" : null}
                                        </div>




                                        {WeightSection ? <div className="mt-3 w-100 d-flex" style={{ flexDirection: "column", gap: "10px" }}>
                                            <strong className="text-info">Weight & Dimensions</strong>
                                            <div>
                                                <label htmlFor="">Total Weight of Products:</label>
                                                <div className="d-flex w-100  align-items-center" style={{ gap: "6px" }}>
                                                    <input type="number" className="" style={{ width: "20%" }} name="weight" onChange={(e: any) => { WandChangeHandler(e) }} />
                                                    <select id="" className="m-0" value={wAndD.weightUnit} name="weightUnit" onChange={(e: any) => { WandChangeHandler(e) }}>
                                                        <option value="">Select Unit</option>
                                                        <option value="Gram">Gram</option>
                                                        <option value="Kilogram">Kilogram</option>
                                                    </select>
                                                </div>


                                            </div>
                                            <div style={{ width: "30%" }}>
                                                <div className="d-flex justify-content-between">

                                                    <label htmlFor=""> Dimensions:</label>
                                                    <select name="dimensionUnit" value={wAndD.dimensionUnit} onChange={(e: any) => { WandChangeHandler(e) }}>
                                                        <option value="">Select Unit</option>
                                                        <option value="meter">Meter</option>
                                                        <option value="centimeter">Centimeter</option>
                                                        <option value="inch">Inch</option>
                                                    </select>
                                                </div>



                                                <div className="d-flex flex-column" style={{ gap: "3px", width: "100%" }}>
                                                    <div className="d-flex justify-content-between" style={{ gap: "10px" }}>
                                                        <label htmlFor="">Length</label> <input type="number" name="length" value={wAndD?.length} onChange={(e: any) => { WandChangeHandler(e) }} />
                                                    </div>
                                                    <div className="d-flex  justify-content-between" style={{ gap: "10px" }}>
                                                        <label htmlFor="">Width</label> <input type="number" name="width" value={wAndD?.width} onChange={(e: any) => { WandChangeHandler(e) }} />
                                                    </div>
                                                    <div className="d-flex  justify-content-between" style={{ gap: "10px" }}>
                                                        <label htmlFor="">Height</label> <input type="number" name="height" value={wAndD?.height} onChange={(e: any) => { WandChangeHandler(e) }} />
                                                    </div>

                                                    <Pbutton onClick={getProvidersPayload} disabled={wAndD?.height !== "" ? false : true}>Search</Pbutton>
                                                </div>


                                            </div>
                                        </div> : null}

                                    </div>

                                    {/* ----------------------------------------HTML for Logistics Part end ----------------------------------------------- */}
                                </div>: null}

                            </div>

                        </div>
                    </div>
                </div>


            </ThemeMaker>
        </>
    )
}

export default OrderDetails;