import React, { useState, useEffect } from 'react'
import ThemeMaker from '../../components/thememaker'
import styled from "styled-components"
import axios from 'axios'

const Style1 = styled.div
  `
  input{
    height: 29px;
    display: flex;
    justify-content: space-evenly
  }
`

export default function Dynamicmain() {

  const [selectedValue, setselectedValue] = useState("")
  const [nameValue, setnameValue] = useState([{
    optionName: "",
    optionValue: "",
    default: false
  }])
  const [optionArray, setoptionArray] = useState([])
  let rawData = {
    label: "",
    type: "",
    value: "",
    placeholder: "",
    required: false,
    status: false
  }
  const [formData, setformData] = useState(rawData)

  const [sele, setsele] = useState({
    id: "",
    option_name: "",
    option_value: "",
    default: false
  })

  const [defaultCheck, setdefaultCheck] = useState(false)

  function selectChangeHandler(e) {
    // alert(e.target.value)
    setselectedValue(e.target.value)
  }



  const handleChange = (index, field, value) => {
    const updatedData = [...nameValue];
    updatedData[index][field] = value;
    setnameValue(updatedData);
    formData.typeSelect = updatedData
  };

  const replicate = () => {
    const updatedData = [...nameValue];
    updatedData.push({ optionName: '', optionValue: '' });
    setnameValue(updatedData);
  };

  async function submitData() {
    console.log("formdata--->", formData)
    let dataToSend
    if(formData?.type =="select"){
      dataToSend = {
        ...formData, value: [...optionArray]
      }
    }else {
      dataToSend = formData
    }

    console.log("data to send ------>", dataToSend)
    const { data } = await axios({
      method: "POST",
      url: `https://staging.thesellerapp.com/admin/api/addattribute`,
      data: dataToSend
    })

    console.log("response from api---->", data)
  }
  // console.log("formdata--->", optionArray)

  function insert(sele, index) {

    setoptionArray((prev) => {
      return [...prev, sele]
    })


    //  setdefaultCheck(false)

  }
  return (
    <ThemeMaker>
      <div className='p-2 m-2 w-100' style={{ color: "black" }}>

        <div className='d-flex mb-4' style={{ gap: "56px" }}>
          <label htmlFor="">Label: </label>
          <input type="text" name='label' onChange={(e) => {
            setformData((prev) => {
              return { ...prev, [e.target.name]: e.target.value }
            })
          }} />
        </div>

        <div>
          <div className='d-flex mb-3' style={{ gap: "56px" }}>
            <label htmlFor="">Type:</label>
            <select id="" className='w-50' name="type" onChange={(e) => {
              selectChangeHandler(e); setformData((prev) => {
                return { ...prev, [e.target.name]: e.target.value }
              })
            }}>
              <option value="">Select Type</option>
              <option value="text">Text</option>
              <option value="number">Number</option>
              <option value="select">Select</option>
            </select>
          </div>

          {
            selectedValue === "text" && <div className='d-flex mb-4' style={{ gap: "50px" }}>   <label htmlFor="">Name:</label> <input type="text" name="value" onChange={(e) => {
              setformData((prev) => {
                return { ...prev, [e.target.name]: e.target.value }
              })
            }} /> </div>
          }

          {
            selectedValue === "number" && <div className='d-flex mb-4' style={{ gap: "40px" }}> <label htmlFor="">Number:</label> <input type="Number" name='value' onChange={(e) => {
              setformData((prev) => {
                return { ...prev, [e.target.name]: e.target.value }
              })
            }} />  </div>
          }

          {
            selectedValue === "select" && <div className='w-100' id='container'>

              <div className='d-flex justify-content-around'>
                <p>Name</p>
                <p>Value</p>
                <p>Default</p>
              </div>

              {nameValue.map((div, index) => (
                <>
                  <Style1 className='w-100 d-flex my-2 justify-content-around' key={index}>
                    <input
                      type='text'
                      className='w-25'
                      // value={div.optionName}
                      onChange={(e) => setsele(value => { return { ...value, option_name: e.target.value, default: false } })}
                    />
                    icon
                    <input
                      type='text'
                      className='w-25'
                      // value={div.optionValue}
                      onChange={(e) => setsele(value => { return { ...value, option_value: e.target.value, id: index } })}
                    />
                    icon

                    <input type="Checkbox"
                      // onChange={(e) => setdefaultCheck(!defaultCheck)}
                      onChange={(e) => {

                        setsele((prev) => {

                          return { ...prev,default: !prev.default }

                        })
                      }}

                    />

                    <h3 onClick={replicate} className='ml-1'>  <i className="fa fa-plus-circle" aria-hidden="true"></i></h3>
                    <button className='btn btn-success' onClick={() => {
                      insert(sele, index)
                    }}>Save</button>
                  </Style1>


                </>
              ))}

            </div>
          }



          <div className='d-flex mb-4x  mt-3' style={{ gap: "12px" }}>
            <label htmlFor="">Placeholder:</label>
            <input type="text" name='placeholder' onChange={(e) => {
              setformData((prev) => {
                return { ...prev, [e.target.name]: e.target.value }
              })
            }} />
          </div>

          <div className='d-flex  align-items-center mt-3'>
            <label htmlFor="" className='m-0'>Status</label>
            <input type="checkbox" className='ml-2' name='status' value={formData?.status} onChange={(e) => {
              setformData((prev) => {
                return { ...prev, [e.target.name]: !formData?.status }
              })
            }} />

            <label htmlFor="">Required</label>
            <input type="checkbox" className='ml-2' name='required' value={formData?.required} onChange={(e) => {
              setformData((prev) => {
                return { ...prev, [e.target.name]: !formData?.required }
              })
            }} />

          </div>



        </div>

        <button onClick={submitData} className='btn btn-success mt-2'>Submit</button>

      </div>
    </ThemeMaker>
  )
}
