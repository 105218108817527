import { useState, useEffect } from "react";
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import ThemeMaker from "../../components/thememaker";
import axios from "axios";
import { tokenDecode } from "../../utils/helper";
import { config } from "../../utils/config";
import {BasicTable} from '../../components/Table';
import { Paginated } from "../../components/Paginated";
import '../../components/table.css';

//import { toast } from "react-toastify";
const { apiEndpoint } = config;

var $ = require('jquery');
const badgeMaker: any = {
    "Created": (order_state: any) => <span className="badge badge-primary">{order_state}</span>,
    "Shipped": (order_state: any) => <span className="badge badge-warning">{order_state}</span>,
    "Delivered": (order_state: any) => <span className="badge badge-success">{order_state}</span>,
    "Canceled": (order_state: any) => <span className="badge badge-danger">{order_state}</span>,
}

const Transction = () => {
    const decoded: any = tokenDecode();
    const [transactionList, setTransactionList] = useState([]);
    const [stransactionList, setStransactionList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [calculation, setCalculation] = useState({
        "order_id": "",
        "sellerapp_commission": "",
        "ondc_commission": "",
        "buyer_commission": "",
        "seller_amount": "",
        "total_amount": ""
    })
    const { data } = decoded;
    const rawData = {
        seller_id: ''
    }
    const [searchData, setSearchData]: any = useState(rawData);
    const { permission: { category, order, product, role, seller, tax, user } } = data;
    const [sellerList, setSellerList] = useState([]);
    const [valueid, setValueid] = useState("");
    const [valueamount, setValueamount] = useState("");
    const [valuedate, setValuedate] = useState("");
    var count=1;

    // const shipping_address = JSON.parse(_orderDetail?.shipping_address);

    const fetchSellerList = async () => {
        setIsLoading(true);
        try {
            const { data } = await axios({
                url: `${apiEndpoint}/gettransactionlist`,
                method: "GET",
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('us_st_d')}`
                }
            });
            console.log("transaction data -> ", data);
            if (data.status === 200) {
                setIsLoading(false);
                setTransactionList(data.data);
                

            }
        } catch (error) {
            console.log("error", error);

            setIsLoading(false);
        }
    }
    const getTransaction = async (order_id: any) => {
        try {
            setCalculation({ ...calculation, order_id });
            const { data } = await axios({
                url: `${apiEndpoint}/getcalculation`,
                method: "POST",
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('us_st_d')}`
                },
                data: {
                    order_id
                }
            });
            console.log("calculation detail ->------   ", data);
            console.log("okss", data.data.seller_amount.toFixed(2));
            
            setCalculation({ ...calculation, ...{
                order_id,
                "sellerapp_commission": parseFloat(data.data.sellerapp_commission).toFixed(2),
                "ondc_commission": parseFloat(data.data.ondc_commission).toFixed(2),
                "buyer_commission": parseFloat(data.data.buyer_commission).toFixed(2),
                "seller_amount": parseFloat(data.data.seller_amount).toFixed(2),
                "total_amount": parseFloat(data.data.total_amount).toFixed(2)
            } });

            if (data.status === 200) {
                //  setorderDetail(data.products);


            }
        } catch (error) {
            console.log("error", error);
        }
    }
    const getSellerList = async () => {
        try {
            const { data } = await axios({
                url: `${apiEndpoint}/getsellerlist`,
                method: "GET",
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('us_st_d')}`
                }
            });
            if (data.status === 200) {
                console.log(data.data)
                setSellerList(data.data);
            }
        } catch (error) {
            console.log("error", error);
        }
    }
    const inputHandler = async(e:any) => {
        setValueid("");
        setValueamount("");
        setValuedate("");
        var optionvalue=e.target.value;
        if(optionvalue){
            try {

                const {
                    seller_id
                } = searchData;
                setSearchData({ ...searchData, [e.target.name]: e.target.value });
                const { data } = await axios({
                        url: `${apiEndpoint}/filtertransactiondataall`,
                        method: "POST",
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('us_st_d')}`
                        },
                        data:{
                            seller_id:optionvalue
                        }
                        
                    });
                    if (data.status === 200) {
                        console.log(data.response);
                        setTransactionList(data.response);
                        setStransactionList(data.response);
                    }
        
                
            } catch (error) {
                console.log("error", error);
            }
        }
        else {
            setTransactionList(stransactionList);
          }
      };
   
      const inputeHandler = async(e:any) => {
        
        const optionvalue=e.target.value;
        setValueid(optionvalue);
        if (optionvalue) {
        try {
            const {
                seller_id
            } = searchData;
            setSearchData({ ...searchData, [e.target.name]: e.target.value });
           const { data } = await axios({
                    url: `${apiEndpoint}/filtertransactionsellerdata`,
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('us_st_d')}`
                    },
                    data: {
                        seller_id:{searchData},
                        order_id:optionvalue
                    }
                    
                });
               
                if (data.status === 200) {
                    console.log(data);
                    setTransactionList(data.response);   
                    
                }

        } catch (error) {
            console.log("error", error);
        }
        } else {
            inputHandler(e);
        }
      };

      const inputeeHandler = async(e:any) => {
        const optionvalue = e.target.value;
        setValueid(optionvalue);
        if (optionvalue) {
        try {
            
            setSearchData({ ...searchData, [e.target.name]: e.target.value });
           const { data } = await axios({
                    url: `${apiEndpoint}/filtertransactionsellerlogin`,
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('us_st_d')}`
                    },
                    data: {
                        order_id:optionvalue
                    }
                    
                });
               
                if (data.status === 200) {
                    console.log(data.response);
                    setTransactionList(data.response);   
                    
                }

        } catch (error) {
            console.log("error", error);
        }
        } else {
            fetchSellerList();
        }
      };
         
      const inputaHandler = async(e:any) => {
        
        const optionvalue=e.target.value;
        setValueamount(optionvalue);
        if (optionvalue) {
        try {
            const {
                seller_id
            } = searchData;
            setSearchData({ ...searchData, [e.target.name]: e.target.value });
           const { data } = await axios({
                    url: `${apiEndpoint}/filtertransactionsellerdata`,
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('us_st_d')}`
                    },
                    data: {
                        seller_id:{searchData},
                        order_total:optionvalue
                    }
                    
                });
               
                if (data.status === 200) {
                    console.log(data);
                    setTransactionList(data.response);   
                    
                }

        } catch (error) {
            console.log("error", error);
        }
        } else {
            inputHandler(e);
        }
      };

      const inputaaHandler = async(e:any) => {
        const optionvalue = e.target.value;
        setValueamount(optionvalue);
        if (optionvalue) {
        try {
            
            setSearchData({ ...searchData, [e.target.name]: e.target.value });
           const { data } = await axios({
                    url: `${apiEndpoint}/filtertransactionsellerlogin`,
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('us_st_d')}`
                    },
                    data: {
                        order_total:optionvalue
                    }
                    
                });
               
                if (data.status === 200) {
                    console.log(data.response);
                    setTransactionList(data.response);   
                    
                }

        } catch (error) {
            console.log("error", error);
        }
        } else {
            fetchSellerList();
        }
      };

    const COLUMNS = [
        {
          Header: "Sn No.",
          Footer: "Sn No.",
          accessor: (row:any,count:any)=>{
              return <>{count+1}</>
          },
          disableFilters: true,
          sticky: "left"
        },
        {
          Header: "Order Id",
          Footer: "Order Id",
          accessor: "order_id",
          sticky: "left"
        },
        {
            Header: "Total Amount",
            Footer: "Total Amount",
            accessor: "order_total",
            sticky: "left"
        },
        {
          Header: "Created At",
          Footer: "Created At",
          accessor: "created_at",
          sticky: "left"
        },
        {
            Header: "Action",
            Footer: "Action",
            accessor: (row:any)=>{
                return <><button className="btn btn-warning" data-toggle="modal" data-target="#exampleModal1"
                onClick={() => getTransaction(row.order_id)}
            >
                <i className="fas fa-eye"></i>
            </button></>
            },
            sticky: "left"
          },
        
      ];
      count++

    useEffect(() => {
        fetchSellerList();
        getSellerList();
    }, [])
    return (
        <>
            <ThemeMaker>
                <div className="row">
                    <div className="col-md-12 pl-4 pr-4">
                        <div className="card shadow mb-4">
                            <div className="card-header py-3">
                                <h6 className="m-0 font-weight-bold text-primary text-left">
                                    Transaction list
                                </h6>
                                {seller?.read?
                                <><b>Filter By Seller: </b><select name="seller_id" id="" onChange={(e:any)=>inputHandler(e)} >
                                <option value="">Select Seller</option>
                                {
                                    sellerList.map((item:any)=>(
                                        <option value={item?.seller_id} key={item?.seller_id}>{item?.name}</option>
                                    ))
                                }
                                       
                                    </select></>
                                :""}
                                <div className="search mt-5">
                                        <div className="row">
                                            <input type="search" name="order_id" placeholder="Search by order id" className="mr-2"  onChange={(e)=>{seller?.read?inputeHandler(e):inputeeHandler(e)}}   value={valueid ? valueid : ""}/>
                                            <input type="search" name="amount"  placeholder="Search by Amount" className="mr-2"   onChange={(e)=>{seller?.read?inputaHandler(e):inputaaHandler(e)}}  value={valueamount ? valueamount : ""}/>

                                        </div>
                                    </div>
                            </div>

                            <div className="card-body">
                                <div className="table-responsive">
                                    {/*<table className="table table-bordered" id="dataTable" width="100%" >
                                        <thead>
                                            <tr>
                                                <th>Sn</th>
                                                <th>Order Id</th>
                                                <th>Total amount</th>
                                                <th>created_at</th>
                                                <th>Action</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                transactionList.map((_item: any, _index: any) => {
                                                    return (
                                                        <tr>
                                                            <td>{_index + 1}</td>
                                                            <td>{_item?.order_id}</td>
                                                            <td>{_item?.order_total}</td>
                                                            <td>{_item?.created_at}</td>
                                                            <td>
                                                                <button className="btn btn-warning" data-toggle="modal" data-target="#exampleModal1"
                                                                    onClick={() => getTransaction(_item?.order_id)}
                                                                >
                                                                    <i className="fas fa-eye"></i>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }


                                        </tbody>
                                    </table>*/}
                            
                                    <Paginated data={transactionList} columns={COLUMNS} />   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade bd-example-modal-lg" id="exampleModal1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLongTitle">Order id: {calculation?.order_id} </h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        <p>  Buyer Commission : {calculation?.buyer_commission} </p>
                                    </div>
                                    <div className="col-md-12">
                                        <p>  ONDC Commission :{calculation?.ondc_commission} </p>
                                    </div>
                                    <div className="col-md-12">
                                        <p>  Seller App Commission : {calculation?.sellerapp_commission} </p>
                                    </div>
                                    <div className="col-md-12">
                                        <p>  Seller Amount : {(calculation?.seller_amount)} </p>
                                    </div>
                                    <div className="col-md-12">
                                        <p>  Total Amount : {calculation?.total_amount} </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ThemeMaker>
        </>
    )
}


export default Transction;