import { useState, useEffect, useCallback } from "react";
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import ThemeMaker from "../../components/thememaker";
import axios from "axios";
import { tokenDecode } from "../../utils/helper";
import { config } from "../../utils/config";
import { BasicTable } from '../../components/Table';
import { Paginated } from "../../components/Paginated";
import '../../components/table.css';

//import { toast } from "react-toastify";
const { apiEndpoint } = config;

var $ = require('jquery');

const badgeMaker: any = {
    "Created": (order_state: any) => <span className="badge badge-primary">{order_state}</span>,
    "Shipped": (order_state: any) => <span className="badge badge-warning">{order_state}</span>,
    "Delivered": (order_state: any) => <span className="badge badge-success">{order_state}</span>,
    "Canceled": (order_state: any) => <span className="badge badge-danger">{order_state}</span>,
}

const Completedorders = () => {
    const decoded: any = tokenDecode();
    const [orderList, setOrderList] = useState([]);
    const [sorderList, setSorderList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [sellerList, setSellerList] = useState([]);
    const [updateOrder_, setUpdateOrder_]: any = useState({
        order_id: "",
        tracking_url: "",
        order_state: ""
    })
    const [orderDetail, setorderDetail]: any = useState([]);
    const [_orderDetail, _setorderDetail]: any = useState({
        billing_address: "",
        contact_number: "",
        created_at: "",
        email: "",
        id: '',
        name: "",
        order_id: "",
        order_state: "",
        provider_id: "",
        shipping_address: "",
        status: "",
        total_price: 0
    });
    const rawData = {
        provider_id: ''
    }
    const [toggle, setToggle] = useState(false);
    const [searchData, setSearchData]: any = useState(rawData);

    const [valuename, setValuename] = useState("");
    const [valueid, setValueid] = useState("");
    const [valueemail, setValueemail] = useState("");
    const [valuecontact, setValuecontact] = useState("");
    const [valuestatus, setValuestatus] = useState("");
    const [valueamount, setValueamount] = useState("");
    const [valuedate, setValuedate] = useState("");
    const { data } = decoded;
    const { permission: { category, order, product, role, seller, tax, user } } = data;
    // const shipping_address = JSON.parse(_orderDetail?.shipping_address);
    var count = 1;
    const fetchSellerList = async () => {
        setIsLoading(true);
        try {
            const { data } = await axios({
                url: `${apiEndpoint}/getsellerordercompletelist`,
                method: "POST",
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('us_st_d')}`
                },
                data: {
                    seller_id: decoded?.data?.user?.seller_id,
                }
            });
            console.log("order data -> ", data);
            if (data.status === 200) {
                setIsLoading(false);
                setOrderList(data.data);
                /*setTimeout(() => {
                    $('#dataTable').DataTable();
                }, 500);
                var someTableDT = $("#dataTable").on("draw.dt", function () {
                    $("#dataTable").find(".dataTables_empty").parents('tbody').empty();
                });
*/
            }
        } catch (error) {
            console.log("error", error);

            setIsLoading(false);
        }
    }
    const getorderDetails = async (order_id: any) => {
        setIsLoading(true);
        try {
            const { data } = await axios({
                url: `${apiEndpoint}/getsellerorder`,
                method: "POST",
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('us_st_d')}`
                },
                data: {
                    order_id
                }
            });
            console.log("order detail ->------   ", data);
            if (data.status === 200) {
                setIsLoading(false);
                setorderDetail(data.products);
                let totalP = 0
                data.products.forEach((item: any) => {
                    totalP += parseFloat(item?.price)
                })
                setUpdateOrder_({
                    ...updateOrder_,
                    order_id: data.data[0]?.order_id,
                    tracking_url: data.data[0]?.tracking_url,
                    order_state: data.data[0]?.order_state
                })
                _setorderDetail({ ..._orderDetail, ...data.data[0], total_price: totalP, billing_address: JSON.parse(data.data[0]?.billing), shipping_address: JSON.parse(data.data[0]?.fulfillments) });
            }
        } catch (error) {
            console.log("error", error);
            setIsLoading(false);
        }
    }
    const updateOrder = async (order_id: any) => {
        try {

            const { data } = await axios({
                url: `${apiEndpoint}/updateorderstatus`,
                method: "POST",
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('us_st_d')}`
                },
                data: updateOrder_
            });
            console.log(data, "datadata");

            if (data.message === "flag1") {
                toast.success("Order updated !", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                fetchSellerList();
            } else {
                toast.error("Something went wrong !", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }

        } catch (error) {
            console.log("update error", error);
            toast.error("404 error !", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            // window.location.reload();

        }
    }
    const getSellerList = async () => {
        try {
            const { data } = await axios({
                url: `${apiEndpoint}/getsellerlist`,
                method: "GET",
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('us_st_d')}`
                }
            });
            if (data.status === 200) {
                console.log(data.data)
                setSellerList(data.data);
            }
        } catch (error) {
            console.log("error", error);
        }
    }
    const inputHandler = async (e: any) => {
        setValuename("");
        setValueid("");
        setValueemail("");
        setValuecontact("");
        setValuestatus("");
        setValueamount("");
        setValuedate("");
        var optionvalue = e.target.value;
        if (optionvalue) {
            try {
                setSearchData({ ...searchData, [e.target.name]: e.target.value });
                const { data } = await axios({
                    url: `${apiEndpoint}/CompletefilterBySeller`,
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('us_st_d')}`
                    },
                    data: {
                        provider_id: optionvalue
                    }

                });

                if (data.status === 200) {
                    console.log(data);
                    setOrderList(data.data);
                    setSorderList(data.data);
                    setToggle(true)
                }


            } catch (error) {
                console.log("error", error);
            }
        }
        else {
            setOrderList(sorderList);
        }
    };
    const inputeHandler = async (e: any) => {

        const optionvalue = e.target.value;
        setValuename(optionvalue);
        if (optionvalue) {
            try {
                const {
                    provider_id
                } = searchData;
                setToggle(true);
                setSearchData({ ...searchData, [e.target.name]: e.target.value });
                const { data } = await axios({
                    url: `${apiEndpoint}/filterbysellerorder`,
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('us_st_d')}`
                    },
                    data: {
                        provider_id: { searchData },
                        name: optionvalue
                    }

                });

                if (data.status === 200) {
                    console.log(data.response);
                    setOrderList(data.response);

                }

            } catch (error) {
                console.log("error", error);
            }
        } else {
            inputHandler(e);
        }
    };
    const inputeeHandler = async (e: any) => {
        const optionvalue = e.target.value;
        setValuename(optionvalue);
        if (optionvalue) {
            try {

                setToggle(true);
                setSearchData({ ...searchData, [e.target.name]: e.target.value });
                const { data } = await axios({
                    url: `${apiEndpoint}/filterordersellerlogin`,
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('us_st_d')}`
                    },
                    data: {
                        name: optionvalue,
                        "order_status" : "Completed"
                    }

                });

                if (data.status === 200) {
                    console.log(data.response);
                    setOrderList(data.response);

                }

            } catch (error) {
                console.log("error", error);
            }
        } else {
            fetchSellerList();
        }
    };
    const inputoHandler = async (e: any) => {

        const optionvalue = e.target.value;
        setValueid(optionvalue);
        if (optionvalue) {
            try {

                setToggle(true);

                //setSearchpData({ ...searchpData, [e.target.name]: e.target.value });
                //console.log("line 239"+rawpData);
                const { data } = await axios({
                    url: `${apiEndpoint}/filterbysellerorder`,
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('us_st_d')}`
                    },
                    data:
                    {
                        provider_id: { searchData },
                        order_id: optionvalue
                    }

                });

                if (data.status === 200) {
                    setOrderList(data.response);
                    console.log("line 332", data.response)
                }


            } catch (error) {
                console.log("error", error);
            }
        } else {
            inputHandler(e);
        }
    };
    const inputooHandler = async (e: any) => {
        const optionvalue = e.target.value;
        setValueid(optionvalue);
        if (optionvalue) {
            try {

                setToggle(true);
                setSearchData({ ...searchData, [e.target.name]: e.target.value });
                const { data } = await axios({
                    url: `${apiEndpoint}/filterordersellerlogin`,
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('us_st_d')}`
                    },
                    data: {
                        order_id: optionvalue,
                        "order_status" : "Completed"
                    }
                });

                if (data.status === 200) {
                    console.log(data.response);
                    setOrderList(data.response);

                }

            } catch (error) {
                console.log("error", error);
            }
        } else {
            fetchSellerList();
        }
    };
    const inputemHandler = async (e: any) => {

        const optionvalue = e.target.value;
        console.log(optionvalue)
        setValueemail(optionvalue);
        if (optionvalue) {
            try {

                setToggle(true);

                //setSearchpData({ ...searchpData, [e.target.name]: e.target.value });
                //console.log("line 239"+rawpData);
                const { data } = await axios({
                    url: `${apiEndpoint}/filterbysellerorder`,
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('us_st_d')}`
                    },
                    data:
                    {
                        provider_id: { searchData },
                        email: optionvalue
                    }

                });

                if (data.status === 200) {
                    setOrderList(data.response);
                    console.log(data.response)
                }


            } catch (error) {
                console.log("error", error);
            }
        } else {
            inputHandler(e);
        }
    };
    const inputeemHandler = async (e: any) => {
        const optionvalue = e.target.value;
        setValueemail(optionvalue);
        if (optionvalue) {
            try {

                setToggle(true);
                setSearchData({ ...searchData, [e.target.name]: e.target.value });
                const { data } = await axios({
                    url: `${apiEndpoint}/filterordersellerlogin`,
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('us_st_d')}`
                    },
                    data: {
                        email: optionvalue,
                        "order_status" : "Completed"
                    }
                });

                if (data.status === 200) {
                    console.log(data.response);
                    setOrderList(data.response);

                }

            } catch (error) {
                console.log("error", error);
            }
        } else {
            fetchSellerList();
        }
    };
    const inputcHandler = async (e: any) => {

        const optionvalue = e.target.value;
        setValuecontact(optionvalue);
        if (optionvalue) {
            try {

                setToggle(true);

                //setSearchpData({ ...searchpData, [e.target.name]: e.target.value });
                //console.log("line 239"+rawpData);
                const { data } = await axios({
                    url: `${apiEndpoint}/filterbysellerorder`,
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('us_st_d')}`
                    },
                    data:
                    {
                        provider_id: { searchData },
                        contact_number: optionvalue
                    }

                });

                if (data.status === 200) {
                    setOrderList(data.response);
                    console.log(data.response)
                }


            } catch (error) {
                console.log("error", error);
            }
        } else {
            inputHandler(e);
        }
    };
    const inputccHandler = async (e: any) => {
        const optionvalue = e.target.value;
        setValuecontact(optionvalue);
        if (optionvalue) {
            try {

                setToggle(true);
                setSearchData({ ...searchData, [e.target.name]: e.target.value });
                const { data } = await axios({
                    url: `${apiEndpoint}/filterordersellerlogin`,
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('us_st_d')}`
                    },
                    data: {
                        contact_number: optionvalue,
                        "order_status" : "Completed"
                    }
                });

                if (data.status === 200) {
                    console.log(data.response);
                    setOrderList(data.response);

                }

            } catch (error) {
                console.log("error", error);
            }
        } else {
            fetchSellerList();
        }
    };
    const inputpHandler = async (e: any) => {

        const optionvalue = e.target.value;
        setValuestatus(optionvalue);
        if (optionvalue) {
            try {

                setToggle(true);

                //setSearchpData({ ...searchpData, [e.target.name]: e.target.value });
                //console.log("line 239"+rawpData);
                const { data } = await axios({
                    url: `${apiEndpoint}/filterbysellerorder`,
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('us_st_d')}`
                    },
                    data:
                    {
                        provider_id: { searchData },
                        status: optionvalue
                    }

                });

                if (data.status === 200) {
                    setOrderList(data.response);
                    console.log(data.response)
                }


            } catch (error) {
                console.log("error", error);
            }
        } else {
            inputHandler(e);
        }
    };
    const inputppHandler = async (e: any) => {
        const optionvalue = e.target.value;
        setValuestatus(optionvalue);
        if (optionvalue) {
            try {

                setToggle(true);
                setSearchData({ ...searchData, [e.target.name]: e.target.value });
                const { data } = await axios({
                    url: `${apiEndpoint}/filterordersellerlogin`,
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('us_st_d')}`
                    },
                    data: {
                        status: optionvalue,
                        "order_status" : "Completed"
                    }
                });

                if (data.status === 200) {
                    console.log(data.response);
                    setOrderList(data.response);

                }

            } catch (error) {
                console.log("error", error);
            }
        } else {
            fetchSellerList();
        }
    };
    const inputaHandler = async (e: any) => {

        const optionvalue = e.target.value;
        setValueamount(optionvalue);
        if (optionvalue) {
            try {

                setToggle(true);

                //setSearchpData({ ...searchpData, [e.target.name]: e.target.value });
                //console.log("line 239"+rawpData);
                const { data } = await axios({
                    url: `${apiEndpoint}/filterbysellerorder`,
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('us_st_d')}`
                    },
                    data:
                    {
                        provider_id: { searchData },
                        order_total: optionvalue
                    }

                });

                if (data.status === 200) {
                    setOrderList(data.response);
                    console.log(data.response)
                }


            } catch (error) {
                console.log("error", error);
            }
        } else {
            inputHandler(e);
        }
    }
    const inputaaHandler = async (e: any) => {
        const optionvalue = e.target.value;
        setValueamount(optionvalue);
        if (optionvalue) {
            try {

                setToggle(true);
                setSearchData({ ...searchData, [e.target.name]: e.target.value });
                const { data } = await axios({
                    url: `${apiEndpoint}/filterordersellerlogin`,
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('us_st_d')}`
                    },
                    data: {
                        order_total: optionvalue,
                        "order_status" : "Completed"
                    }
                });

                if (data.status === 200) {
                    console.log(data.response);
                    setOrderList(data.response);

                }

            } catch (error) {
                console.log("error", error);
            }
        } else {
            fetchSellerList();
        }
    }
    const inputdHandler = async (e: any) => {

        const optionvalue = e.target.value;
        setValuedate(optionvalue);
        if (optionvalue) {
            try {

                setToggle(true);

                //setSearchpData({ ...searchpData, [e.target.name]: e.target.value });
                //console.log("line 239"+rawpData);
                const { data } = await axios({
                    url: `${apiEndpoint}/filterbysellerorder`,
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('us_st_d')}`
                    },
                    data:
                    {
                        provider_id: { searchData },
                        created_at: optionvalue
                    }

                });

                if (data.status === 200) {
                    setOrderList(data.response);
                    console.log(data.response)
                }


            } catch (error) {
                console.log("error", error);
            }
        } else {
            inputHandler(e);
        }
    }
    const inputddHandler = async (e: any) => {
        const optionvalue = e.target.value;
        setValuedate(optionvalue);
        if (optionvalue) {
            try {

                setToggle(true);
                setSearchData({ ...searchData, [e.target.name]: e.target.value });
                const { data } = await axios({
                    url: `${apiEndpoint}/filterordersellerlogin`,
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('us_st_d')}`
                    },
                    data: {
                        created_at: optionvalue,
                        "order_status" : "Completed"
                    }
                });

                if (data.status === 200) {
                    console.log(data.response);
                    setOrderList(data.response);

                }

            } catch (error) {
                console.log("error", error);
            }
        } else {
            fetchSellerList();
        }
    }
    const updateOrderChangeHandler = (e: any) => {
        setUpdateOrder_({ ...updateOrder_, [e.target.name]: e.target.value })
    }

    const COLUMNS = [
        {
            Header: "Sn No.",
            Footer: "Sn No.",
            accessor: (row: any, count: any) => {
                return <>{count + 1}</>
            },
            disableFilters: true,
            sticky: "left"
        },
        {
            Header: "Order Id",
            Footer: "Order Id",
            accessor: "order_id",
            sticky: "left"
        },
        {
            Header: "Customer name",
            Footer: "Customer name",
            accessor: "name",
            sticky: "left"
        },
        {
            Header: "Email",
            Footer: "Email",
            //   accessor: "email",
            accessor: ((row: any) => {
                return row?.address?.end?.contact?.email
            }),
            sticky: "left"
        },
        {
            Header: "Contact",
            Footer: "Contact",
            accessor: ((row: any) => {
                return row?.address?.end?.contact?.phone
            }),
            sticky: "left"
        },
        {
            Header: "Total",
            Footer: "Total",
            accessor: "order_total",
            sticky: "left"
        },
        {
            Header: "Payment Status",
            Footer: "Payment Status",
            accessor: (row: any) => {
                return <>{row.status === "NOT-PAID" ? "COD" : "PAID"}</>
            },
            sticky: "left"
        },
        {
            Header: "Created At",
            Footer: "Created At",
            accessor: "created_at",
            sticky: "left"
        },

        {
            Header: "Action",
            Footer: "Action",
            accessor: (row: any) => {
                return <><Link to={`../../ordercompleteinfo/${row.order_id}`} className="btn btn-success btn-circle"><i className="fas fa-pen"></i></Link></>
            },
            sticky: "left"
        },

    ];
    count++
    useEffect(() => {
        fetchSellerList();
        getSellerList();
    }, [])
    /*useCallback(() => {
        getSellerList();
    }, [])*/
    return (
        <>
            <ThemeMaker>
                <div className="row">
                    <div className="col-md-12 pl-4 pr-4">
                        <div className="card shadow mb-4">
                            <div className="card-header py-3">
                                <h6 className="m-0 font-weight-bold text-primary text-left">
                                    Completed Orders
                                </h6>
                                {seller?.read ?
                                    <><b>Filter By Seller: </b><select name="provider_id" id="" onChange={(e: any) => inputHandler(e)} >
                                        <option value="">Select Seller</option>
                                        {
                                            sellerList.map((item: any) => (
                                                <option value={item?.provider_id} key={item?.provider_id}>{item?.name}</option>
                                            ))
                                        }

                                    </select></>
                                    : ""}
                                <div className="search mt-5">
                                    <div className="row">
                                        <input type="search" name="order_id" placeholder="Search by order id" className="mr-2" onChange={(e) => { seller?.read ? inputoHandler(e) : inputooHandler(e) }} value={valueid ? valueid : ""} />
                                        <input type="search" name="name" placeholder="Search by name" className="mr-2" onChange={(e) => { seller?.read ? inputeHandler(e) : inputeeHandler(e) }} value={valuename ? valuename : ""} />
                                        <input type="search" name="email" placeholder="Search by email" className="mr-2" onChange={(e) => { seller?.read ? inputemHandler(e) : inputeemHandler(e) }} value={valueemail ? valueemail : ""} />
                                        <input type="search" name="contact_number" placeholder="Search by Contact" className="mr-2" onChange={(e) => { seller?.read ? inputcHandler(e) : inputccHandler(e) }} value={valuecontact ? valuecontact : ""} />
                                        {/*<input type="search" name="payment_status"  placeholder="Search by Payment Status" className="mr-2" onChange={(e)=>{seller?.read?inputpHandler(e):inputppHandler(e)}}  value={valuestatus ? valuestatus : ""}/>*/}
                                        <select name="payment_status" onChange={(e) => { seller?.read ? inputpHandler(e) : inputppHandler(e) }} value={valuestatus ? valuestatus : ""} >
                                            <option >Select Payment Status</option>
                                            <option value="NOT-PAID">Cod</option>
                                            <option value="pa">Paid</option>
                                        </select>
                                        {/* <input type="search" name="amount" placeholder="Search by Amount" className="mr-2" onChange={(e) => { seller?.read ? inputaHandler(e) : inputaaHandler(e) }} value={valueamount ? valueamount : ""} /> */}
                                        <b style={{ lineHeight: "34px", marginLeft: "10px" }}>Search By Date:</b> <input type="date" name="date" placeholder="Search by Date" className="mr-2" onChange={(e) => { seller?.read ? inputdHandler(e) : inputddHandler(e) }} value={valuedate ? valuedate : ""} />

                                    </div>
                                </div>
                            </div>

                            <div className="card-body">
                                <div className="table-responsive">
                                    {/*}  <table className="table table-bordered" id="dataTable" width="100%" >
                                        <thead>
                                            <tr>
                                                <th>Sn</th>
                                                <th>Order Id</th>
                                                <th>Customer name</th>
                                                <th>Email</th>
                                                <th>Contact</th>
                                                <th>Payment Status</th>
                                                <th>created_at</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                orderList.map((_item: any, _index: any) => {
                                                    return (
                                                        <tr>
                                                            <td>{_index + 1}</td>
                                                            <td>{_item?.order_id}</td>
                                                            <td>{_item?.name}</td>
                                                            <td>{_item?.email}</td>
                                                            <td>{_item?.contact_number}</td>
                                                            <td>
                                                                {_item?.status === "NOT-PAID" ? "COD" : "PAID"}
                                                            </td>
                                                            
                                                            <td> {_item?.created_at} </td>
                                                            <td>

                                                                <div className="row">
                                                                    <div className="col-md-4">
                                                                        
                                                                        <Link to={`/orderdetails/${_item?.order_id}`}
                                                                        >
                                                                            <i className="fas fa-pen"></i>
                                                                        </Link>
                                                                    </div>
                                                                    {/* <div className="col-md-4">
                                                                        <Link to={`#`} className="btn btn-danger btn-circle">
                                                                            <i className="fas fa-trash"></i>
                                                                        </Link>
                                                                    </div> 
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }


                                        </tbody>
                                        </table>*/}
                                    <Paginated data={orderList} columns={COLUMNS} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </ThemeMaker>
        </>
    )
}

export default Completedorders;